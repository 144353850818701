import { useState } from "react";
import Form from "V2.0/components/Form";
import { message } from "antd";
import { useFields } from "./models/useFields";

function SMSTriggerForm({ smsTrigger, onCancel, onSubmit }) {
  const [inputField, setInputField] = useState({
    actionName: smsTrigger?.actionName || "",
    phoneNumber: smsTrigger?.phoneNumber || "",
    trigger: smsTrigger?.trigger || "",
    contentText: smsTrigger?.contentText || "",
    triggerEvent: smsTrigger?.triggerEvent || "duringCall",
  });

  const [previewData, setPreviewData] = useState(inputField);
  const { template, schema } = useFields(previewData);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const addTriggerButtonInfo = {
    buttons: "2",
    primaryLabel: "Save",
    secondaryLabel: "Cancel",
  };

  const handleYes = (data) => {
    const payload = {
      ...data,
      description: data.triggerEvent === "duringCall" ? data.description : "",
      actionFunction: "SMS",
    };
    onSubmit(payload);
    setLoading(false);
  };

  const getPreviewDataInParent = (dataDuplicate) => {
    if (!dataDuplicate) {
      setPreviewData(inputField);
    }
    setPreviewData(dataDuplicate);
  };

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={addTriggerButtonInfo}
      onSubmit={handleYes}
      handleSecondary={onCancel}
      loading={loading}
      setLoading={setLoading}
      getValue={getPreviewDataInParent}
    />
  );
}

export { SMSTriggerForm };
