import { useState } from "react";
import Form from "V2.0/components/Form";

function WelcomeMessageSettingsForm(props) {
  const {
    loading,
    setLoading,
    template,
    schema,
    welcomeMessageSettings,
    onSubmit,
  } = props;
  const [errorMessage] = useState({});

  const buttonInfo = { label: "Save", style: "right-side" };

  return (
    <div className="flex flex-col">
      <div></div>
      <Form
        key={JSON.stringify(welcomeMessageSettings)}
        errorMessage={errorMessage}
        inputField={welcomeMessageSettings}
        template={template}
        schema={schema}
        onSubmit={onSubmit}
        loading={loading}
        setLoading={setLoading}
        buttonInfo={buttonInfo}
        enableActionsOnChange={true}
        showRevert={true}
      />
    </div>
  );
}

export { WelcomeMessageSettingsForm };
