import { CheckboxGroup, CheckboxField, Checkbox } from "V2.0/common/checkbox";
import { Label, Description } from "V2.0/common/fieldset";

export function CalendarSearchEnabled(props) {
  const { calendarSearchEnabled, updateCalendarSearchEnabled } = props;

  return (
    <CheckboxGroup>
      <CheckboxField>
        <Checkbox
          name="ghl_crm_search"
          onChange={() => {
            updateCalendarSearchEnabled();
          }}
          value={calendarSearchEnabled}
          checked={calendarSearchEnabled}
        />
        <Label>Allow Appointment Bookings</Label>
        <Description>
          Turn on this to allow bookings in your GHL Calendar
        </Description>
      </CheckboxField>
    </CheckboxGroup>
  );
}
