import { useState } from "react";
import { ExtractionFields } from "./ExtractionFields";
import { ExtractionFieldAdd } from "./ExtractionFieldAdd";
import { ExtractionFieldsConfirm } from "./ExtractionFieldsConfirm";

import TabsWithBadge from "V2.0/common/tabsWithBadge";
import { updateAssistant } from "../../service";
import { useMandatoryFields } from "./useMandatoryFields";
import { MandatoryFieldsEdit } from "./MandatoryFieldsEdit";

const TABS = {
  EXTRACTION_FIELDS: "Extraction Fields",
  NEW_EXTRACTION: "New Extraction",
};

const Tabs = {
  [TABS.EXTRACTION_FIELDS]: (props) => <ExtractionFields {...props} />,
  [TABS.NEW_EXTRACTION]: (props) => <ExtractionFieldsConfirm {...props} />,
};

function Extraction({ extraction, syncAssistant, workspaceId, assistantId }) {
  const [open, setOpen] = useState(false);
  const [newExtractions, setNewExtractions] = useState([]);
  const [tabState, setTabState] = useState(TABS.EXTRACTION_FIELDS);
  const {
    mandatoryFields,
    isMandatoryField,
    addToMandatoryField,
    removeFromMandatoryField,
    changeMandatoryCondition,
  } = useMandatoryFields(extraction);

  const onAddField = () => {
    setOpen(true);
    setTabState(TABS.NEW_EXTRACTION);
  };

  const tabs = Object.values(TABS).reduce((acc, tab) => {
    return [...acc, { name: tab, href: "#", current: tab === tabState }];
  }, []);

  return (
    <div className="w-full flex flex-col items-center justify-center p-4">
      <main className="w-full">
        <div className="flex flex-col gap-y-4">
          <div className="flex justify-between items-center">
            <div className="flex gap-x-2">
              <p className="font-medium text-md">Extraction</p>
            </div>
            <div className="flex gap-x-2">
              {tabState === TABS.EXTRACTION_FIELDS && newExtractions.length === 0 && (
                <MandatoryFieldsEdit
                  extraction={extraction}
                  workspaceId={workspaceId}
                  assistantId={assistantId}
                  syncAssistant={syncAssistant}
                />
              )}
              <ExtractionFieldAdd
                open={open}
                onAddField={onAddField}
                onConfirmAddField={(extraction) => {
                  console.log({ extraction });
                  setNewExtractions((prevExtractions) => [
                    ...prevExtractions,
                    extraction,
                  ]);
                  setTabState(TABS.NEW_EXTRACTION);
                }}
                onClose={() => setOpen(false)}
              />
            </div>
          </div>
          <TabsWithBadge
            className="border-b"
            tabs={tabs}
            onTabChange={(e, { tab }) => setTabState(tab.name)}
          />
          <div>
            {Tabs[tabState]({
              extraction,
              newExtractions,
              workspaceId,
              assistantId,
              setOpen,
              setNewExtractions,
              syncAssistant,
              onSubmit: () => setTabState(TABS.EXTRACTION_FIELDS),
              mandatoryFields,
              addToMandatoryField,
              removeFromMandatoryField,
              isMandatoryField,
              changeMandatoryCondition,
            })}
          </div>
        </div>
      </main>
    </div>
  );
}

export { Extraction };
