import { useNavigate } from "react-router-dom";
import { TableComponent } from "V2.0/HomePages/HomeModule/CustomAPIs/components/TableComponent";

const knowledgeBaseTableHeaders: { key: string; label: string }[] = [
  {
    key: "label",
    label: "Knowledge Base Name",
  },
];

export function KnowledgebaseTable(props) {
  const { knowledgeBases, onDelete } = props;
  const navigate = useNavigate();

  return (
    <TableComponent
      headers={knowledgeBaseTableHeaders}
      data = {knowledgeBases ?? []}
      onDelete = {(item) => onDelete(item._id)}
      onRowClick={(item) =>
        navigate(`/knowledgebases/${item._id}`, { state: { knowledgeBase: item } })
      }
    />
  );
}
