import { useState } from "react";
import { PhoneNumberAdd } from "./PhoneNumberAdd";
import { PhoneNumberTable } from "./PhoneNumberTable";
import { CommunicationBindingDelete } from "../CommunicationBindingDelete";

function PhoneNumberBinding(props) {
  const {
    isOpen,
    onClose,
    onBindingAdd,
    phoneNumbers,
    communicationBinding,
    onBindingConfirmDelete,
    onBindingDelete,
  } = props;

  return (
    <>
      <div className="flex flex-col w-2/4 mx-auto mt-4 p-4">
        <div className="w-full flex items-center justify-between">
          <p>Phone Numbers</p>
          <PhoneNumberAdd
            onPhoneNumberAdd={onBindingAdd}
            phoneNumbers={phoneNumbers}
          />
        </div>
        <PhoneNumberTable
          communicationBinding={communicationBinding}
          onItemDelete={onBindingDelete}
        />
      </div>
      {isOpen && (
        <CommunicationBindingDelete
          open={true}
          onClose={onClose}
          title="Delete Phone Number"
          onDelete={onBindingConfirmDelete}
        />
      )}
    </>
  );
}

export { PhoneNumberBinding };
