import { useEffect, useMemo, useState } from "react";
import { useAssistant } from "../Assistants/Assistant";

const defaultOption = { id: 0, label: "Please select phone number", value: "" };

function useAssistantPhoneNumbers(assistantId, workspaceId) {
  const [fromNumber, setFromNumber] = useState("");
  const { assistant, fetchAssistant } = useAssistant(assistantId, workspaceId);

  useEffect(() => {
    if (assistantId && workspaceId) {
      fetchAssistant();
    }
  }, [assistantId, workspaceId]);

  const phoneNumbers = useMemo(() => {
    if (assistant) {
      return assistant?.communicationBinding?.map((binding, idx) => {
        return {
          id: idx + 1,
          label: binding.identifier,
          value: binding.identifier,
        };
      });
    }

    return [];
  }, [assistant]);

  return {
    phoneNumbers: [defaultOption, ...phoneNumbers],
    fromNumber,
    setFromNumber,
  };
}

export { useAssistantPhoneNumbers };
