import { useState } from "react";
import Form from "V2.0/components/Form";
import Joi from "joi-browser";
import { message } from "antd";

const template = [
  { name: "name", label: "Agent Name", type: "text" },
  { name: "phoneNumber", label: "Agent Phone Number", type: "text" },
  { name: "email", label: "Agent Email", type: "email" },
  // { name: "user", label: "Agent ID", type: "text" },
  {
    name: "trigger",
    label: "Trigger",
    type: "textArea",
    style: { height: "100px" },
  },
];
const schema = {
  name: Joi.string().required().label("Agent Name"),
  phoneNumber: Joi.string().allow(null, ""),
  email: Joi.string().email(),
  // user: Joi.string().allow(null, ""),
  trigger: Joi.string().allow(null, ""),
};

function TransferAgentForm(props) {
  const { inputField, onSubmit, onCancel, loading, setLoading, buttonInfo } =
    props;

  return (
    <Form
      template={template}
      schema={schema}
      inputField={inputField}
      onSubmit={onSubmit}
      handleSecondary={onCancel}
      loading={loading}
      setLoading={setLoading}
      buttonInfo={buttonInfo}
    />
  );
}

export { TransferAgentForm };
