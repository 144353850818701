import { useState } from "react";

import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useMandatoryFields } from "./useMandatoryFields";
import { Button } from "V2.0/common/button";
import {
  Dialog,
  DialogTitle,
  DialogBody,
  DialogActions,
} from "V2.0/common/dialog";
import InputComponent from "V2.0/components/InputComponent";
import {
  Table,
  TableHeader,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
} from "V2.0/common/table";
import { Switch } from "V2.0/common/switch";
import { putExtraction } from "../../service";

function MandatoryFieldsEdit(props) {
  const { extraction, workspaceId, assistantId, syncAssistant } = props;
  const {
    mandatoryFields,
    addToMandatoryField,
    removeFromMandatoryField,
    changeMandatoryCondition,
    isMandatoryField,
  } = useMandatoryFields(extraction);
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);

  const handleSave = async () => {
    const payload = {
      ...extraction,
      mandatoryFields,
    };
    try {
      const response = await putExtraction({
        workspaceId,
        assistantId,
        payload,
      });

      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            extraction: response.data,
          };
        });
      }
    } catch (e) {
      console.error("Error updating mandatory fields");
    }
  };

  return (
    <>
      <Button outline onClick={onOpen}>
        <Cog6ToothIcon /> Mandatory Fields
      </Button>
      <Dialog size="xl" open={open} onClose={onClose}>
        <DialogTitle>Mandatory Fields</DialogTitle>
        <DialogBody>
          <div className="flex flex-col gap-y-4">
            <div>
              <InputComponent
                label="Condition"
                type="select"
                options={[
                  {
                    id: 1,
                    label: "OR",
                    value: "OR",
                  },
                  {
                    id: 2,
                    label: "AND",
                    value: "AND",
                  },
                ]}
                value={mandatoryFields.condition}
                onChange={(e) => changeMandatoryCondition(e.target.value)}
              />
            </div>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeader>Field name</TableHeader>
                    <TableHeader>Mandatory</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {extraction.extractionParameters.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.parameterName}</TableCell>
                        <TableCell>
                          <Switch
                            checked={isMandatoryField(item.parameterName)}
                            onChange={(checked) => {
                              checked
                                ? addToMandatoryField(item.parameterName)
                                : removeFromMandatoryField(item.parameterName);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        </DialogBody>
        <DialogActions>
          <Button outline onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { MandatoryFieldsEdit };
