import { useState } from "react";
import { CustomAPIs } from "./CustomAPIs/CustomAPIs";
import { Extraction } from "./Extraction/Extraction";
import { Webhooks } from "./Webhooks/Webhooks";
import { TransferAgents } from "../TransferSettings/TransferAgents";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { ParentLayout } from "./components/ParentLayout";
import { SectionLayout } from "./components/SectionLayout";
import { MainLayout } from "./components/MainLayout";
import { SectionItem } from "./components/SectionItem";

enum TOOL {
  EXTRACTION = "Extraction",
  CUSTOM_API = "Custom API",
  TRANSFER_AGENTS = "Transfer Agents",
}

const ToolComponent = {
  [TOOL.CUSTOM_API]: (props) => <CustomAPIs {...props} />,
  [TOOL.EXTRACTION]: (props) => <Extraction {...props} />,
  [TOOL.TRANSFER_AGENTS]: (props) => <TransferAgents {...props} />,
};

function EmbedAssistantTools(props) {

  const [activeTool, setActiveTool] = useState(TOOL.CUSTOM_API);

  const tools = Object.values(TOOL).reduce((acc, tool) => {
    return [...acc, { name: tool, href: "#", current: tool === activeTool }];
  }, []);

  return (
    <ParentLayout>
      <SectionLayout>
        {tools.map((tool) => (
          <SectionItem
            sectionName={tool.name}
            isActive={tool.current}
            onSectionClick={() => setActiveTool(tool.name)}
          />
        ))}
      </SectionLayout>
      <MainLayout>
        {ToolComponent[activeTool]({
          ...props
        })}
      </MainLayout>
    </ParentLayout>
  );
}

export { EmbedAssistantTools };
