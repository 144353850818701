import { useState } from "react";
import Form from "V2.0/components/Form";
import { message } from "antd";
import Joi from "joi-browser";

const schema = {
    actionName: Joi.string().required().max(50).label("Event name"),
    trigger: Joi.string().max(1500).label("Description"),
  };


const template = [
    {
      name: "actionName",
      label: "Name of the event",
      type: "text",
    },

    {
      name: "trigger",
      label: "Describe when do you want to stop the call recording",
      type: "textArea",
      style: { height: "150px" },
    },
  ];

function StopCallRecordingForm({ stopCallRecordingTrigger, onCancel, onSubmit }) {
  const [inputField, setInputField] = useState({
    actionName: stopCallRecordingTrigger?.actionName || "",
    trigger: stopCallRecordingTrigger?.trigger || "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const addTriggerButtonInfo = {
    buttons: "2",
    primaryLabel: "Save",
    secondaryLabel: "Cancel",
  };

  const handleYes = async (data) => {
    const payload = {
      ...data,
      actionFunction: "STOPRECORDING",
    };
    onSubmit(payload);
    setLoading(false);
  };

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={addTriggerButtonInfo}
      onSubmit={handleYes}
      handleSecondary={onCancel}
      loading={loading}
      setLoading={setLoading}
    />
  );
}

export { StopCallRecordingForm };
