import { Dialog, DialogBody, DialogTitle } from "V2.0/common/dialog";
import { StopCallRecordingForm } from "./StopCallRecordingForm";

function StopCallRecordingTriggerEdit({
  stopCallRecordingTrigger,
  open,
  onClose,
  onTriggerEdit,
}) {
  return (
    <Dialog className="" size="2xl" open={open} onClose={onClose}>
      <DialogTitle>
        <p className="text-lg">Edit Stop Recording Trigger</p>
      </DialogTitle>
      <DialogBody className="text-sm/6">
        <StopCallRecordingForm
          stopCallRecordingTrigger={stopCallRecordingTrigger}
          onCancel={onClose}
          onSubmit={onTriggerEdit}
        />
      </DialogBody>
    </Dialog>
  );
}

export { StopCallRecordingTriggerEdit };
