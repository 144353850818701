import React, { useState, useCallback, useEffect } from "react";
import { Switch } from "V2.0/common/switch";

function useCustomAPIsSettings(customAPIsEnabled, updateAssistantCustomAPI) {
  const [isCustomAPIsEnabled, setIsCustomAPIEnabled] = useState(false);

  useEffect(() => {
    setIsCustomAPIEnabled(customAPIsEnabled);
  }, [customAPIsEnabled]);

  const toggleCustomAPIs = useCallback(() => {
    setIsCustomAPIEnabled(!isCustomAPIsEnabled);

    const payload = {
      customAPIsEnabled: !isCustomAPIsEnabled,
    };

    updateAssistantCustomAPI(payload);
  }, [isCustomAPIsEnabled, updateAssistantCustomAPI]);

  return {
    isCustomAPIsEnabled,
    toggleCustomAPIs,
  };
}

export function EnableCustomAPIs({
  updateAssistantCustomAPI,
  customAPIsEnabled,
}) {
  const { isCustomAPIsEnabled, toggleCustomAPIs } = useCustomAPIsSettings(
    customAPIsEnabled,
    updateAssistantCustomAPI
  );

  return (
    <Switch
      color="emerald"
      id={`checkbox-${isCustomAPIsEnabled}`}
      checked={isCustomAPIsEnabled}
      onClick={toggleCustomAPIs}
    />
  );
}
