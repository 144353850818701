import { useState } from "react";
import TabsWithBadge from "V2.0/common/tabsWithBadge";
import { GPTResponseModel, PromptSettings } from "../ModelSettings";
import { VoiceAssistantTools } from "../AssistantTools/VoiceAssistantTools";
import { Button } from "V2.0/common/button";
import DemoComponent from "V2.0/components/DemoComponent";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  ChevronLeftIcon,
  Cog8ToothIcon,
  DevicePhoneMobileIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { EmailAssistantSettings } from "../AssistantSettings/EmailAssistantSettings";
import { EmailAssistantTools } from "../AssistantTools/EmailAssistantTools";
import { emailAssistant } from "../mock/emailAssistant";
import { KnowledgeBase } from "../RAG/KnowledgeBase/KnowledgeBase";

enum TABS {
  PROMPT = "Prompt",
  TOOLS = "Tools",
  SETTINGS = "Settings",
  KNOWLEDGE = "Knowledge",
}

const tabsMap = {
  [TABS.PROMPT]: (props) => <PromptSettings {...props} />,
  [TABS.SETTINGS]: (props) => <EmailAssistantSettings {...props} />,
  [TABS.TOOLS]: (props) => <EmailAssistantTools {...props} />,
  [TABS.KNOWLEDGE]: (props) => <KnowledgeBase {...props} />,
};

const TAB_ICON = {
  [TABS.PROMPT]: DocumentDuplicateIcon,
  [TABS.KNOWLEDGE]: FolderIcon,
  [TABS.SETTINGS]: Cog8ToothIcon,
  [TABS.TOOLS]: WrenchIcon,
};

function EmailAssistant(props) {
  const { workspaceId, name, assistantId, modelSettings, syncAssistant } =
    props;
  const { projects, activeProject } = useOutletContext<{
    projects: [];
    activeProject: object;
  }>();
  const [activeTab, setActiveTab] = useState<TABS>(TABS.PROMPT);
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const tabs = Object.values(TABS).reduce((acc, tab) => {
    const Icon = TAB_ICON[tab];
    return [
      ...acc,
      {
        name: tab,
        href: "#",
        current: tab === activeTab,
        icon: <Icon className="size-4" />,
      },
    ];
  }, []);

  return (
    <>
      <div className="flex h-full">
        <div className="w-full h-full border-r">
          <div>
            <div className="flex justify-between p-4 items-center border-b">
              <div className="flex gap-x-2">
                <Button outline onClick={() => navigate("/assistants")}>
                  <ChevronLeftIcon />
                </Button>
                <div>
                  <p className="font-semibold text-md">{name}</p>
                  <p className="text-sm">ID: &nbsp;{assistantId} </p>
                </div>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setChatOpen(true);
                  }}
                >
                  Test Assistant
                </Button>
              </div>
            </div>
            <div className="flex justify-end p-4 border-b">
              <GPTResponseModel
                settings={modelSettings}
                syncAssistant={syncAssistant}
                workspaceId={workspaceId}
                assistantId={assistantId}
              />
            </div>
          </div>
          <div className="">
            <div className="border-b px-4">
              <TabsWithBadge
                tabs={tabs}
                className=""
                onTabChange={(e, { tab, index }) => setActiveTab(tab.name)}
              />
            </div>
            <div>
              {tabsMap[activeTab]({
                ...props,
              })}
            </div>
          </div>
        </div>
      </div>
      {chatOpen && (
        <DemoComponent
          open={chatOpen}
          setOpen={setChatOpen}
          projects={projects}
          activeProject={activeProject}
          SidebarTitle="Chat with Assistant"
        />
      )}
    </>
  );
}

export { EmailAssistant };
