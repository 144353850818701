import { useState } from "react";
import { Dialog, DialogBody, DialogTitle } from "V2.0/common/dialog";
import { Button } from "V2.0/common/button";
import { PlusIcon } from "@heroicons/react/16/solid";
import Joi from "joi-browser";
import { message } from "antd";
import Form from "V2.0/components/Form";
import { WebhookForm } from "./WebhookForm";

export function WebhookCreate({ onSubmit }) {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Button outline onClick={() => setIsOpen(true)}>
        <PlusIcon /> Create Webhook
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Webhook Create</DialogTitle>
        <DialogBody>
          <WebhookForm handleClose={handleClose} onSubmit={onSubmit} />
        </DialogBody>
      </Dialog>
    </>
  );
}
