import { useState } from "react";

import { ExistingAPIs } from "./IndividualAPI/ExistingAPIs";

import { Button } from "V2.0/common/button";

import { IndividualAPI } from "./IndividualAPI";

import { PlusCircleIcon } from "@heroicons/react/16/solid";
import { useCustomAPIs } from "./hooks/useCustomAPIs";
import { individualAPI } from "./IndividualAPI/models/individualAPI";
import { EnableCustomAPIs } from "./EnableCustomAPIs";

const MODE = {
  ADD: "ADD",
  EDIT: "EDIT",
  NONE: null,
};

export function CustomAPIs({
  workspaceId,
  customAPIs,
  assistantId,
  syncAssistant,
}) {
  const [mode, setMode] = useState({
    mode: MODE.NONE,
    item: null,
    index: null,
  });

  const {
    customAPIsArray,
    customAPIsEnabled,
    updateCustomApis,
    updateCustomApisArray,
    removeCustomApisArray,
    addCustomApisArray,
  } = useCustomAPIs({ workspaceId, assistantId, customAPIs, syncAssistant });

  return (
    <div className="w-full flex flex-col items-center justify-center p-4">
      <main className="w-full">
        <div className="flex flex-col gap-y-2">
          <div className="flex justify-between mt-4 pb-4 mb-4">
            <div className="flex items-end gap-x-2">
              {/* <img src={customAPI} alt="custom api" width="30" /> */}
              <p className="font-semibold text-md">Custom APIs</p>
              <div className="self-end">
                <EnableCustomAPIs
                  customAPIsEnabled={customAPIsEnabled}
                  updateAssistantCustomAPI={updateCustomApis}
                />
              </div>
            </div>
            {!mode.mode && (
              <Button
                className=""
                outline
                onClick={() => {
                  setMode({
                    mode: MODE.ADD,
                    item: individualAPI,
                    index: null,
                  });
                }}
              >
                <PlusCircleIcon />
                Add Custom API
              </Button>
            )}
          </div>
          <div className="max-h-[calc(100vh-350px)] overflow-scroll px-4">
            {!mode.mode && (
              <ExistingAPIs
                customApis={customAPIsArray}
                onDelete={(id) => removeCustomApisArray(id)}
                setActiveItem={(item, index) =>
                  setMode({ mode: MODE.EDIT, item, index })
                }
              />
            )}
            {mode.mode === MODE.EDIT && (
              <IndividualAPI
                item={mode.item}
                onConfirm={(data) => updateCustomApisArray(mode.item._id, data)}
                goBack={() =>
                  setMode({ mode: MODE.NONE, item: null, index: null })
                }
              />
            )}
            {mode.mode === MODE.ADD && (
              <IndividualAPI
                onConfirm={(data) => addCustomApisArray(data)}
                item={mode.item}
                goBack={() =>
                  setMode({ mode: MODE.NONE, item: null, index: null })
                }
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
