import { useState } from "react";
import { TableComponent } from "V2.0/HomePages/HomeModule/CustomAPIs/components/TableComponent";
import { TransferSettings } from "./TransferSettings";
import { TransferAgentEdit } from "./TransferAgentEdit";
import { TransferAgentDelete } from "./TransferAgentDelete";
import { TransferAgentCreate } from "./TransferAgentCreate";
import {
  deleteAgent,
  postAgent,
  putAgent,
  putTransferSettings,
} from "../service";
import { TransferAgentToggle } from "./TransferAgentToggle";

const agentHeaders = [
  {
    key: "name",
    label: "Agent Name",
  },
  {
    key: "phoneNumber",
    label: "Agent Phone Number",
  },
  {
    key: "email",
    label: "Agent Email",
  },
];

export function TransferAgents(props) {
  const { transferSettings, assistantId, syncAssistant, workspaceId, assistantType } = props;
  const { agents } = transferSettings;

  const [open, setOpen] = useState({
    mode: null,
    item: null,
    index: -1,
  });

  const onAgentEdit = (item, index) => {
    setOpen({
      mode: "edit",
      item,
      index,
    });
  };
  const onAgentDelete = (item, index) => {
    setOpen({
      mode: "delete",
      item,
      index,
    });
  };
  const updateSettings = async (updatedSettings) => {
    try {
      const response = await putTransferSettings({
        workspaceId,
        assistantId,
        payload: updatedSettings,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            transferSettings: response.data,
          };
        });
      }
    } catch (e) {
      console.error("Error updating transfer settings");
      throw e;
    }
  };

  const updateAgent = async (updatedAgent) => {
    try {
      const response = await putAgent({
        workspaceId,
        assistantId,
        agentId: open.item._id,
        payload: updatedAgent,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            transferSettings: {
              ...prevAssistant.transferSettings,
              agents: response.data,
            },
          };
        });
      }
    } catch (e) {
      console.error("Error adding agent");
      throw e;
    }
  };

  const removeAgent = async () => {
    try {
      const response = await deleteAgent({
        workspaceId,
        assistantId,
        agentId: open.item._id,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            transferSettings: {
              ...prevAssistant.transferSettings,
              agents: response.data,
            },
          };
        });
      }
    } catch (e) {
      console.error("Error deleting agent");
      throw e;
    }
  };

  const onAgentCreate = async (data) => {
    try {
      const response = await postAgent({
        workspaceId,
        assistantId,
        payload: data,
      });
      if (response.status === 201) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            transferSettings: {
              ...prevAssistant.transferSettings,
              agents: response.data,
            },
          };
        });
      }
    } catch (e) {
      console.error("Error creating agents");
      throw e;
    }
  };

  const onClose = () => {
    setOpen({
      mode: null,
      item: null,
      index: -1,
    });
  };

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center p-4">
        <div className="w-full flex flex-col gap-y-2">
          <div className="flex justify-between">
            <div className="flex items-center gap-x-2">
              <p className="font-semibold text-lg">Agents</p>
              <TransferAgentToggle
                isEnabled={transferSettings.transferEnabled}
                onToggle={(state) => {
                  updateSettings({ transferEnabled: state });
                }}
              />
            </div>
            <div className="flex gap-x-2">
              <TransferSettings
              assistantType={assistantType}
                settings={transferSettings}
                updateSettings={updateSettings}
              />
              <TransferAgentCreate createAgent={onAgentCreate} />
            </div>
          </div>
        </div>
        <div className="w-full max-h-[calc(100vh-320px)] overflow-auto my-4">
          <TableComponent
            headers={agentHeaders}
            data={agents}
            onEdit={onAgentEdit}
            onDelete={onAgentDelete}
          />
        </div>
      </div>
      {open.mode === "edit" && (
        <TransferAgentEdit
          updateAgent={updateAgent}
          agent={open.item}
          open={true}
          onClose={onClose}
        />
      )}
      {open.mode === "delete" && (
        <TransferAgentDelete
          deleteAgent={removeAgent}
          open={true}
          onClose={onClose}
        />
      )}
    </>
  );
}
