import { useState } from "react";

import Form from "../../../components/Form";
import Joi from "joi-browser";
import { sheetURLFieldSchema, sheetURLFieldTemplate } from "./fields/sheetURLField";

export function Call(props) {
  const { onBulkUpload, onClose, loading, setLoading, isDisabled, fromNumber, assistantId } = props;

  const [inputField] = useState({
    sheetURL: "",
    fromNumber,
    triggerType: "",
    campaignType: "Call",
    assistantId
  });

  const [errorMessage] = useState({});

  const template = [
    sheetURLFieldTemplate,
    {
      name: "triggerType",
      label: "Trigger type",
      type: "select",
      options: [
        {
          id: 0,
          value: "",
          label: "No selection",
        },
        {
          id: 1,
          value: "triggerImmediately",
          label: "Trigger Immediately",
        },
        {
          id: 2,
          value: "triggerBasedOnCampaign",
          label: "Trigger based on campaign configuration",
        },
      ],
    },
  ];

  const schema = {
    ...sheetURLFieldSchema,
    fromNumber: Joi.string()
      .regex(/^\+?[0-9]+$/)
      .required()
      .label("From Number"),
    triggerType: Joi.string().required().label("Trigger Type"),
    campaignType: Joi.string().required().label("Campaign Type"),
    assistantId: Joi.string().required()
  };

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={{
        style: "",
        buttons: "2",
        primaryLabel: "Upload",
        secondaryLabel: "Cancel",
      }}
      onSubmit={onBulkUpload}
      loading={loading}
      setLoading={setLoading}
      handleSecondary={onClose}
    />
  );
}
