import { putMessagingSettings } from "../service";

function useMessagingSettings({ syncAssistant, workspaceId, assistantId }) {
  const updateMessagingSettings = async (payload) => {
    try {
      const response = await putMessagingSettings({
        workspaceId,
        assistantId,
        payload,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            messagingSettings: response.data,
          };
        });
      }
    } catch (e) {
      console.error("Error updating messaging settings");
    }
  };

  return { updateMessagingSettings };
}

export { useMessagingSettings };
