import { PlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Button } from "V2.0/common/button";
import {
  Dialog,
  DialogTitle,
  DialogBody,
  DialogActions,
} from "V2.0/common/dialog";
import { Field, Label, Description } from "V2.0/common/fieldset";
import { Input } from "V2.0/common/input";

 

export function KnowledgebaseCreate(props) {
  const { onCreateKnowledgebase } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [knowledgebaseData, setKnowledgebaseData] = useState({
    name: "",
    description: "",
    assistantType: "",
  });

  const handleChange = (fieldWithValue) => {
    setKnowledgebaseData((knowledgebaseData) => ({
      ...knowledgebaseData,
      ...fieldWithValue,
    }));
  };

  const handleCreate = () => {
    const { name } = knowledgebaseData;

    if (name === "") {
      return;
    }

    onCreateKnowledgebase({label: name});
  };

  return (
    <>
      <Button outline onClick={() => setIsOpen(true)}>
        <PlusIcon />
        Create Knowledge base
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Create Knowledge Base</DialogTitle>
        <DialogBody>
          <div className="flex flex-col gap-y-4">
            <Field>
              <Label>Knowledge Base Name</Label>
              <Input
                type="text"
                value={knowledgebaseData.name}
                onChange={(e) => handleChange({ name: e.target.value })}
              />
            </Field>
          </div>
        </DialogBody>
        <DialogActions>
          <Button outline onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleCreate}>Create</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
