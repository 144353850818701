import { useState } from "react";
import { Button } from "V2.0/common/button";
import { Dialog, DialogTitle, DialogBody } from "V2.0/common/dialog";
import { PlusIcon } from "@heroicons/react/24/outline";
import { TransferAgentForm } from "./TransferAgentForm";
import { message } from "antd";

function TransferAgentEdit(props) {
  const { agent, updateAgent, open, onClose } = props;
  const [loading, setLoading] = useState(false);

  const [inputField] = useState({
    name: agent.name,
    phoneNumber: agent.phoneNumber,
    email: agent.email,
    trigger: agent.trigger,
  });

  const handleSubmit = (data) => {
    updateAgent(data)
      .then(() => {
        setLoading(false);
        onClose();
        message.success("Agent updated");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button>
        <PlusIcon /> Edit Agent
      </Button>
      {open && (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>Edit Agent</DialogTitle>
          <DialogBody>
            <TransferAgentForm
              inputField={inputField}
              onSubmit={handleSubmit}
              onCancel={onClose}
              loading={loading}
              setLoading={setLoading}
              buttonInfo={{
                buttons: "2",
                primaryLabel: "Save",
                secondaryLabel: "Cancel",
              }}
            />
          </DialogBody>
        </Dialog>
      )}
    </>
  );
}

export { TransferAgentEdit };
