import {
  Table,
  TableHeader,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
} from "V2.0/common/table";
import { Button } from "V2.0/common/button";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Badge } from "V2.0/common/badge";

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + "...";
};
const MAX_LENGTH = 50;

function ExtractionTable(props) {
  const { extraction, onEdit, onDelete, isMandatoryField, mandatoryFields } =
    props;
  return (
    <Table className="">
      <TableHead>
        <TableRow>
          <TableHeader>Field name</TableHeader>
          <TableHeader>Type</TableHeader>
          <TableHeader>Instruction</TableHeader>
          <TableHeader>Mandatory Field</TableHeader>
          <TableHeader>Action</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {extraction &&
          extraction.extractionParameters.map((item, index) => {
            const isFieldMandatory = isMandatoryField(item.parameterName);
            return (
              <TableRow key={index}>
                <TableCell className="font-medium">
                  {item.parameterName}
                </TableCell>
                <TableCell>{item.parameterType}</TableCell>
                <TableCell key={index}>
                  {truncateText(item.parameterDescription, MAX_LENGTH)}
                </TableCell>
                <TableCell>
                  <Badge color={isFieldMandatory ? "lime" : "orange"}>
                    {isMandatoryField(item.parameterName) ? "Yes" : "No"}
                  </Badge>
                </TableCell>
                <TableCell>
                  <div className="-mx-3 -my-1.5 sm:-mx-2.5 flex gap-x-2">
                    <Button outline onClick={() => onEdit(item, index)}>
                      <PencilIcon />
                    </Button>
                    <Button outline onClick={() => onDelete(item, index)}>
                      <TrashIcon />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
}

export { ExtractionTable };
