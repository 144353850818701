import React from "react";
import "../index.css";

import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import ProtectedRoute from "./common/protectedRoute";

import Home from "./HomePages/Home";
import Login from "./SignupnLogin/Login";
import SignUp from "./SignupnLogin/Signup";
import Logout from "./SignupnLogin/Logout";
import Welcome from "./OnboardingPages/Welcome";
import ThirdPartAuthSuccess from "./HomePages/HomeIntegrations/ThirdPartAuthSuccess";
import PaymentSuccess from "./PaymentPages.js/PaymentSuccess";

import { Dashboard } from "V2.0/HomePages/Dashboard";
import { KnowledgeBases, KnowledgeBase  } from "./HomePages/Knowledge_base";
import { Contacts } from "./HomePages/Contacts";
import HomePrompt from "./HomePages/HomePrompt";
import HomeAPISettings from "./HomePages/HomeAPISettings";
import { Threads } from "./HomePages/Threads";

import { Integrations } from "./HomePages/Integrations";
import Whatsapp from "./HomePages/Integrations/Whatsapp";
import { MessengerIntegration } from "./HomePages/Integrations/Messenger";
import { TwilioIntegration } from "./HomePages/Integrations/Twilio";
import { S3Integration } from "./HomePages/Integrations/S3";
import { PineConeIntegration } from "./HomePages/Integrations/Pinecone";
import { ChromaDBIntegration } from "./HomePages/Integrations/ChromaDB";
import HomeCampaigns from "V2.0/HomePages/HomeCampaigns";
import Schedule from "V2.0/HomePages/HomeCampaign/Configuration";
import Modules from "./HomePages/Modules";
import { WebHooks } from "./HomePages/Modules/Webhooks";
import { CustomAPIs } from "./HomePages/HomeModule/CustomAPIs";
import { HumanAgentTransfer } from "./HomePages/Modules/Human_agents_transfer";
import { Extractions } from "./HomePages/Modules/Extractions";
import { Triggers } from "./HomePages/Modules/Triggers";
import { EndCall } from "./HomePages/Modules/End_call";
import PIIEncryptionComponent from "./HomePages/HomeModule/PIIEncryptionComponent";
import Recording from "./HomePages/HomeModule/Recording";
import VoiceEmbed from "./HomePages/HomeModule/VoiceEmbed";
import ChatEmbed from "./HomePages/HomeModule/ChatEmbed";
import AcuityCalendarScheduling from "./HomePages/HomeModule/Acuity";
import { GHLIntegration } from "./HomePages/Integrations/GHL";
import GHLCRM from "./HomePages/HomeModule/GHLCRM";
import ZohoCRM from "./HomePages/HomeModule/ZohoCRM";
import { ZohoCRMIntegration } from "./HomePages/Integrations/ZohoCRM";
import HomeBilling, { Billing } from "./HomePages/HomeBilling";
import ForgotPassword from "./SignupnLogin/ForgotPassword";
import ResetPassword from "./SignupnLogin/ResetPassword";
import { PlivoIntegration } from "./HomePages/Integrations/Plivo";

import { Assistants, Assistant } from "V2.0/HomePages/Assistants";

function ProtectedRouteLayout() {
  return (
    <ProtectedRoute>
      <Outlet />
    </ProtectedRoute>
  );
}

function App() {
  return (
    <div>
      <Routes>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/not-found" replace />} />

        <Route element={<ProtectedRouteLayout />}>
          <Route path="/logout" element={<Logout />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/onboarding/:companyId" element={<Welcome />} />
          <Route path="/oauth/callback" element={<ThirdPartAuthSuccess />} />
          <Route path="/success/:sessionId?" element={<PaymentSuccess />} />
          <Route element={<Home />}>
            <Route
              index
              path="/"
              element={<Navigate to="dashboard" replace />}
            />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="assistants" element={<Assistants />} />
            <Route path="assistants/:assistantId" element={<Assistant />} />
            {/* <Route path="knowledge" element={<KnowledgeBase />} /> */}
            <Route path="knowledgebases" element={<KnowledgeBases /> }/>
            <Route path="knowledgebases/:knowledgebaseId" element={<KnowledgeBase />} />

            <Route path="threads" element={<Threads />} />
            <Route path="contacts" element={<Contacts />} />
            {/* <Route path="prompt" element={<HomePrompt />} /> */}
            <Route path="campaign" element={<HomeCampaigns />} />
            <Route path="/campaign/configuration" element={<Schedule />} />
            <Route path="settings" element={<HomeAPISettings />} />
            <Route path="integrations" element={<Integrations />} />
            <Route path="billing" element={<Billing />} />

            {/* <Route path="modules" element={<Modules />}>
              <Route path="webhooks" element={<WebHooks />} />
              <Route path="custom-api" element={<CustomAPIs />} />
              <Route path="agent-transfer" element={<HumanAgentTransfer />} />
              <Route path="extraction" element={<Extractions />} />
              <Route path="triggers" element={<Triggers />} />
              <Route path="end-call" element={<EndCall />} />
              <Route path="zoho-crm" element={<ZohoCRM />} />
              <Route path="ghl" element={<GHLCRM />} />
              <Route
                path="pii-encryption"
                element={<PIIEncryptionComponent />}
              />
              <Route path="recording" element={<Recording />} />
              <Route path="voice-embed" element={<VoiceEmbed />} />
              <Route path="chat-embed" element={<ChatEmbed />} />
              <Route
                path="acuity-scheduling"
                element={<AcuityCalendarScheduling />}
              />
            </Route> */}
            <Route path="integrations/plivo" element={<PlivoIntegration />} />
            <Route path="integrations/whatsapp" element={<Whatsapp />} />
            <Route
              path="integrations/messenger"
              element={<MessengerIntegration />}
            />
            <Route path="integrations/ghl" element={<GHLIntegration />} />
            <Route
              path="integrations/zoho-crm"
              element={<ZohoCRMIntegration />}
            />
            <Route path="integrations/twilio" element={<TwilioIntegration />} />
            <Route path="integrations/s3" element={<S3Integration />} />
            <Route
              path="integrations/pinecone"
              element={<PineConeIntegration />}
            />
            <Route
              path="integrations/chromadb"
              element={<ChromaDBIntegration />}
            />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
