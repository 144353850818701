import { useState, useEffect } from "react";
import { message } from "antd";

import { ExtractionFieldEdit } from "./ExtractionFieldEdit";
import { ExtractionFieldDelete } from "./ExtractionFieldDelete";
import { ExtractionTable } from "./ExtractionTable";
import { putExtraction } from "../../service";

function ExtractionFields({
  extraction,
  workspaceId,
  assistantId,
  syncAssistant,
  mandatoryFields,
  isMandatoryField
}) {
  const [loading, setLoading] = useState(false);

  const [action, setAction] = useState({
    mode: null,
    item: null,
    index: null,
  });

  // Function to truncate the text

  const onEditItem = (item, index) => {
    setAction({
      mode: "EDIT",
      item,
      index,
    });
  };

  const onDeleteItem = (item, index) => {
    setAction({
      mode: "DELETE",
      item,
      index,
    });
  };

  const resetAction = () => {
    setAction({
      mode: null,
      item: null,
      index: null,
    });
  };

  const handleDeleteConfirmation = async (req) => {
    message.success("Please wait. We're sending the request.");

    setLoading(true);

    const newArray = extraction.extractionParameters;
    const modifiedArray = newArray.filter((_, i) => i !== action.index);

    const payload = {
      ...extraction,
      extractionParameters: modifiedArray,
    };

    try {
      setLoading(true);
      const response = await putExtraction({
        workspaceId,
        assistantId,
        payload,
      });
      if (response.status === 200) {
        setLoading(false);
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            extraction: response.data,
          };
        });
        console.log("Success");
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const onSubmit = async (data) => {
    const newArray = extraction.extractionParameters;

    if (action.index >= 0 && action.index < newArray.length) {
      newArray[action.index] = data;
    }

    const payload = {
      ...extraction,
      extractionParameters: newArray,
    };

    try {
      const response = await putExtraction({
        workspaceId,
        assistantId,
        payload,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            extraction: response.data,
          };
        });
        console.log("Success");
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };
  
  return (
    <>
      {extraction && extraction.extractionParameters && (
        <ExtractionTable
          extraction={extraction}
          onEdit={onEditItem}
          onDelete={onDeleteItem}
          mandatoryFields={mandatoryFields}
          isMandatoryField={isMandatoryField}
        />
      )}
      {action.mode === "DELETE" && (
        <ExtractionFieldDelete
          isOpen={true}
          onClose={resetAction}
          onDelete={handleDeleteConfirmation}
          loading={loading}
        />
      )}
      {action.mode === "EDIT" && (
        <ExtractionFieldEdit
          isOpen={true}
          onClose={resetAction}
          item={action.item}
          index={action.index}
          extraction={extraction}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}

export { ExtractionFields };
