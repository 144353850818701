import { useState, useEffect } from "react";
import { Button } from "V2.0/common/button";
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog, DialogTitle, DialogBody } from "V2.0/common/dialog";
import TranscriptionSettings from "./TranscriptionSettings";
import { DialogTitleWithClose } from "V2.0/components/DialogTitleWithClose";

function Transcription(props) {
  const { settings, syncAssistant, workspaceId, assistantId } = props;
  const { deepgramSettings } = settings;
  const [open, setOpen] = useState(false);
  const inputField = {
    transcriptionLanguage: deepgramSettings.transcriptionLanguage,
  };

  const languages = {
    bg: { label: "Bulgarian" },
    ca: { label: "Catalan" },
    cs: { label: "Czech" },
    da: { label: "Danish" },
    "da-DK": { label: "Danish (Denmark)" },
    nl: { label: "Dutch" },
    en: { label: "English" },
    "en-US": { label: "English (United States)" },
    "en-AU": { label: "English (Australia)" },
    "en-GB": { label: "English (United Kingdom)" },
    "en-NZ": { label: "English (New Zealand)" },
    "en-IN": { label: "English (India)" },
    et: { label: "Estonian" },
    fi: { label: "Finnish" },
    "nl-BE": { label: "Flemish" },
    fr: { label: "French" },
    "fr-CA": { label: "French (Canada)" },
    de: { label: "German" },
    "de-CH": { label: "German (Switzerland)" },
    el: { label: "Greek" },
    hi: { label: "Hindi" },
    hu: { label: "Hungarian" },
    id: { label: "Indonesian" },
    it: { label: "Italian" },
    ja: { label: "Japanese" },
    ko: { label: "Korean" },
    "ko-KR": { label: "Korean (South Korea)" },
    lv: { label: "Latvian" },
    lt: { label: "Lithuanian" },
    ms: { label: "Malay" },
    no: { label: "Norwegian" },
    pl: { label: "Polish" },
    pt: { label: "Portuguese" },
    "pt-BR": { label: "Portuguese (Brazil)" },
    ro: { label: "Romanian" },
    ru: { label: "Russian" },
    sk: { label: "Slovak" },
    es: { label: "Spanish" },
    "es-419": { label: "Spanish (Latin America)" },
    sv: { label: "Swedish" },
    "sv-SE": { label: "Swedish (Sweden)" },
    th: { label: "Thai" },
    "th-TH": { label: "Thai (Thailand)" },
    tr: { label: "Turkish" },
    uk: { label: "Ukrainian" },
    vi: { label: "Vietnamese" },
    "zh-CN": { label: "Chinese (Mandarin, Simplified)" },
    "zh-TW": { label: "Chinese (Mandarin, Traditional)" },
    mutli: { label: "Multilingual (Spanish/English)" },
  };

  const languageLabel = languages[inputField.transcriptionLanguage]?.label;

  return (
    <div className="flex items-center border rounded-lg">
      <div className="px-2 border-r">
        <p className="text-sm">{inputField && languageLabel}</p>
      </div>
      <Button className="rounded-none" plain onClick={() => setOpen(true)}>
        <PencilIcon />
      </Button>
      {open && (
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <DialogTitle>
            <DialogTitleWithClose
              title="Select Language"
              onClose={() => {
                setOpen(false);
              }}
            />
          </DialogTitle>
          <DialogBody>
            <TranscriptionSettings
              settings={deepgramSettings}
              workspaceId={workspaceId}
              assistantId={assistantId}
              syncAssistant={syncAssistant}
            />
          </DialogBody>
        </Dialog>
      )}
    </div>
  );
}

export { Transcription };
