import { PlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Button } from "V2.0/common/button";
import { Dialog, DialogTitle, DialogBody } from "V2.0/common/dialog";
import Joi from "joi-browser";
import Form from "V2.0/components/Form";

const assistantOptions = [
  { id: 0, value: "", label: "(None selected)" },
  { id: 1, value: "voice", label: "Voice Assistant" },
  { id: 2, value: "sms", label: "Sms Assistant" },
  { id: 3, value: "whatsapp", label: "Whatsapp Assistant" },
  // { id: 4, value: "email", label: "Email Assistant" },
  // { id: 5, value: "chat", label: "Chat Assistant" },
];

const template = [
  { name: "name", label: "Assistant Name", type: "text" },
  {
    name: "description",
    label: "Description",
    type: "textArea",
    style: { height: "100px" },
    description: "Describe Who the assistant is?",
  },
  {
    name: "assistantType",
    label: "Assistant Type",
    type: "select",
    options: assistantOptions,
  },
];

const schema = {
  name: Joi.string().required(),
  description: Joi.string().allow(null, ""),
  assistantType: Joi.string().required(),
};

const buttonInfo = {
  buttons: "2",
  primaryLabel: "Create",
  secondaryLabel: "Cancel",
};

export function AssistantCreate(props) {
  const { onCreateAssistant } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [assistantData, setAssistantData] = useState({
    name: "",
    description: "",
    assistantType: "",
  });

  const handleCreate = (data) => {
    onCreateAssistant(data);
  };

  return (
    <>
      <Button outline onClick={() => setIsOpen(true)}>
        <PlusIcon />
        Create Assistant
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Create Assistant</DialogTitle>
        <DialogBody>
          <Form
            inputField={assistantData}
            buttonInfo={buttonInfo}
            onSubmit={handleCreate}
            loading={loading}
            setLoading={setLoading}
            handleSecondary={() => setIsOpen(false)}
            template={template}
            schema={schema}
          />
        </DialogBody>
      </Dialog>
    </>
  );
}
