import { useState } from "react";
import { Button } from "V2.0/common/button";
import { Dialog, DialogTitle, DialogBody } from "V2.0/common/dialog";
import { PlusIcon } from "@heroicons/react/24/outline";
import { TransferAgentForm } from "./TransferAgentForm";
import { message } from "antd";

function TransferAgentCreate(props) {
  const { createAgent } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [inputField] = useState({});

  const handleSubmit = (data) => {
    createAgent(data)
      .then(() => {
        setLoading(false);
        setOpen(false);
        message.success("Agent added");
      })
      .catch((e) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <PlusIcon /> Add Agent
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Add Agent</DialogTitle>
        <DialogBody>
          <TransferAgentForm
            inputField={inputField}
            onSubmit={handleSubmit}
            onCancel={() => setOpen(false)}
            loading={loading}
            setLoading={setLoading}
            buttonInfo={{
              buttons: "2",
              primaryLabel: "Add",
              secondaryLabel: "Cancel",
            }}
          />
        </DialogBody>
      </Dialog>
    </>
  );
}

export { TransferAgentCreate };
