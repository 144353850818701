import { useState, useEffect } from "react";
import { Switch } from "V2.0/common/switch";

function CustomActionToggle({ isEnabled, onToggleCustomAction }) {
  const [customActionsEnabled, setCustomActionsEnabled] = useState(false);

  useEffect(() => {
    setCustomActionsEnabled(isEnabled);
  }, [isEnabled]);

  const handleToggle = async () => {
    setCustomActionsEnabled(!customActionsEnabled);
    onToggleCustomAction(!customActionsEnabled);
  };

  return (
    <Switch
      color="emerald"
      id={`checkbox-${customActionsEnabled}`}
      checked={customActionsEnabled}
      onClick={handleToggle}
    />
  );
}

export { CustomActionToggle };
