import { useState, useEffect } from "react";

import Form from "../../../components/Form";
import Joi from "joi-browser";
import { message } from "antd";
import httpService from "../../../services/httpService";
import {
  addContactAttributesIfPresent,
  fieldTemplates,
  fieldSchema,
  fieldValues,
} from "./common/contactFields";

export function Whatsapp(props) {
  const { addUserToCampaign, onClose, projectId, assistantId, fromNumber } = props;
  const [loading, setLoading] = useState(false);
  const [messageTemplates, setMessageTemplates] = useState([]);

  const inputField = {
    ...fieldValues,
    toNumber: "",
    fromNumber,
    triggerType: "",
    campaignType: "Whatsapp",
    template: JSON.stringify({
      templateName: "",
      templateLanguage: "",
    }),
    assistantId
  };

  const [errorMessage] = useState({});

  const fetchWhatsappMessageTemplates = async () => {
    try {
      const response = await httpService.get(
        `/whatsapp/manage/getTemplates/${projectId}`
      );
      const data = response.data;
      const templateNames = data.map((template) => ({
        templateName: template.name,
        templateLanguage: template.language,
      }));
      setMessageTemplates(templateNames);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchWhatsappMessageTemplates();
  }, []);

  const template = [
    ...fieldTemplates,
    {
      name: "toNumber",
      label: "To Number",
      description: "Please pre-fix country code to the number",
      type: "text",
    },
    {
      name: "template",
      label: "Message Template",
      description: "Select one of the pre approved templates",
      type: "select",
      options: [
        { id: 0, value: JSON.stringify({}), label: "(No template selected)" },
      ].concat(
        messageTemplates.map((messageTemplate, index) => ({
          id: index,
          value: JSON.stringify(messageTemplate),
          label: messageTemplate.templateName,
        }))
      ),
    },

    {
      name: "triggerType",
      label: "Trigger type",
      type: "select",
      options: [
        {
          id: 0,
          value: "",
          label: "No selection",
        },
        {
          id: 1,
          value: "triggerImmediately",
          label: "Trigger Immediately",
        },
        {
          id: 2,
          value: "triggerBasedOnCampaign",
          label: "Trigger based on campaign configuration",
        },
      ],
    },
  ];

  const schema = {
    ...fieldSchema,
    toNumber: Joi.string()
      .regex(/^\+?[0-9]+$/)
      .required()
      .label("To Number"),
    template: Joi.object({
      templateName: Joi.string().required(),
      templateLanguage: Joi.string().required(),
    })
      .required()
      .label("Message Template"),
    fromNumber: Joi.string()
      .regex(/^\+?[0-9]+$/)
      .required()
      .label("From Number"),
    triggerType: Joi.string().required().label("Trigger Type"),
    campaignType: Joi.string().required().label("campaignType"),
    assistantId: Joi.string().required()
  };

  function onAddUserToCampaign(data) {
    const { template, firstName, lastName, email, ...rest } = data;
    const { templateName, templateLanguage } = JSON.parse(template);

    const updatedData = {
      ...rest,
      templateName,
      templateLanguage,
      ...addContactAttributesIfPresent(data),
    };

    addUserToCampaign(
      updatedData,
      (response) => {
        if (response.status === 200) {
          message.success("Success");
          setLoading(false);
          onClose();
        }
      },
      (error) => {
        message.error(error?.response?.data?.msg || "Failed to add contact");
        setLoading(false);
        onClose();
        console.error(error);
      }
    );
  }

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={{
        style: "",
        buttons: "2",
        primaryLabel: "Add",
        secondaryLabel: "Close",
      }}
      onSubmit={onAddUserToCampaign}
      loading={loading}
      setLoading={setLoading}
      handleSecondary={onClose}
    />
  );
}
