import http from "./httpService";
const apiEndpoint = "/conversation";

function allConversation(projectId) {
  return http.get(apiEndpoint + "/whitelabelGetAllThreads/" + projectId);
}

function deleteConversation(projectId, conversationId) {
  return http.delete(
    apiEndpoint +
      "/" +
      "whitelabelDeleteConversation" +
      "/" +
      projectId +
      "/" +
      conversationId
  );
}

function downloadExtractions({ projectId, body }) {
  return http.post(
    `${apiEndpoint}/whitelabelFetchExtractedData/${projectId}`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

function downloadConversations({ projectId, body }) {
  return http.post(
    `${apiEndpoint}/whitelabelFetchConversationData/${projectId}`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

function getPaginatedConversations({ projectId, page, pageSize }) {
  return http.get(
    `${apiEndpoint}/whitelabelGetPaginatedThreads/${projectId}?page=${page}&pageSize=${pageSize}`
  );
}

function getConversationById({ projectId, conversationId }) {
  return http.get(
    `${apiEndpoint}/whitelabelGetConversation/${projectId}/${conversationId}`
  );
}

function getConversationsBySearchTerm({ projectId, searchTerm }) {
  return http.get(
    `${apiEndpoint}/whitelabelSearchConversations/${projectId}?searchTerm=${searchTerm}`
  );
}

function changeHumanAgent(data, projectId, conversationId) {
  return http.patch(
    `/conversation/whitelabelChangeHumanAgentAssignment/${projectId}`,
    JSON.stringify({
      conversationId,
      ...data,
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

function removeHumanAgent(projectId, conversationId) {
  return http.put(
    `/conversation/whitelabelRemoveHumanAgentAssignment/${projectId}/${conversationId}`
  );
}

function getMediaFromConversation({ projectId, conversationId, mediaId }) {
  return http.get(
    `${apiEndpoint}/whitelabelFetchWhatsAppMedia/${projectId}/${conversationId}/${mediaId}`,
    {
      responseType: "blob",
    }
  );
}

function getRecordingLink({ workspaceId, conversationId }) {
  return http.get(
    `${apiEndpoint}/portalGetRecordingLink/${workspaceId}/${conversationId}`,
    { responseType: "blob" }
  );
}

export {
  allConversation,
  deleteConversation,
  downloadConversations,
  downloadExtractions,
  getPaginatedConversations,
  getConversationById,
  getConversationsBySearchTerm,
  changeHumanAgent,
  removeHumanAgent,
  getMediaFromConversation,
  getRecordingLink,
};
