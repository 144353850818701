import {
  deleteCustomActionsArray,
  postCustomActionsArray,
  putCustomActions,
  putCustomActionsArray,
} from "../service";

function useCustomActions({ syncAssistant, workspaceId, assistantId }) {
  const removeCustomAction = async (customActionId) => {
    try {
      const response = await deleteCustomActionsArray({
        workspaceId,
        assistantId,
        customActionId,
      });

      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            customActions: {
              ...prevAssistant.customActions,
              customActionsArray: response.data,
            },
          };
        });

        return;
      }
    } catch (e) {
      console.error("Error deleting custom action");
    }
  };

  const toggleCustomAction = async (payload) => {
    try {
      const response = await putCustomActions({
        workspaceId,
        assistantId,
        payload,
      });

      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            customActions: response.data,
          };
        });
        return;
      }
    } catch (e) {
      console.error("Error deleting custom action");
    }
  };

  const updateCustomAction = async (data, customActionId) => {
    try {
      const response = await putCustomActionsArray({
        workspaceId,
        assistantId,
        customActionId,
        payload: data,
      });

      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            customActions: {
              ...prevAssistant.customActions,
              customActionsArray: response.data,
            },
          };
        });
        return;
      }
    } catch (e) {
      console.error("Error deleting custom action");
    }
  };

  const createCustomAction = async (data) => {
    try {
      const response = await postCustomActionsArray({
        workspaceId,
        assistantId,
        payload: data,
      });

      if (response.status === 201) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            customActions: {
              ...prevAssistant.customActions,
              customActionsArray: response.data,
            },
          };
        });
        return;
      }
    } catch (e) {
      console.error("Error deleting custom action");
    }
  };

  return {
    removeCustomAction,
    updateCustomAction,
    createCustomAction,
    toggleCustomAction,
  };
}

export { useCustomActions };
