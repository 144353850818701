import {
  descriptionFieldRequiredSchema,
  descriptionFieldTemplate,
  commonFields,
  commonSchema,
} from "./fields";
import { useMemo } from "react";

export function useFields(previewData) {
  const template = useMemo(() => {
    if (previewData.triggerEvent === "duringCall") {
      return descriptionFieldTemplate;
    } else {
      return commonFields;
    }
  }, [previewData.triggerEvent]);

  const schema = useMemo(() => {
    if (previewData.triggerEvent === "duringCall") {
      return descriptionFieldRequiredSchema;
    } else {
      return commonSchema;
    }
  }, [previewData.triggerEvent]);

  return { template, schema };
}
