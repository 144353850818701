import { useState } from "react";
import { Button } from "V2.0/common/button";
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog, DialogBody, DialogTitle } from "V2.0/common/dialog";

import { GPTResponseModelSettings } from "./GPTResponseModelSettings";
import { DialogTitleWithClose } from "V2.0/components/DialogTitleWithClose";

const GPTResponseModels = {
  "gpt-4o-mini": "GPT 4o mini",
  "superdash-35-turbo": "GPT 3.5 Turbo",
  "gemini-1.5-flash": "Gemini 1.5 Flash",
  "groq/llama3-8b-8192": "LLaMA3 8b",
  "groq/llama3-70b-8192": "LLaMA3 70b",
  "groq/mixtral-8x7b-32768": "Mixtral 8x7b",
};

function GPTResponseModel(props) {
  const { settings, syncAssistant, workspaceId, assistantId } = props;
  const { gptResponseModel } = settings;
  const [open, setOpen] = useState(false);

  return (
    <div className="flex items-center border rounded-lg">
      <div className="px-2 border-r">
        <p className="text-sm">
          {GPTResponseModels[gptResponseModel] ?? "GPT 4o mini"}
        </p>
      </div>
      <Button plain onClick={() => setOpen(true)}>
        <PencilIcon />
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <DialogTitleWithClose
            title="Select Model"
            onClose={() => {
              setOpen(false);
            }}
          />
        </DialogTitle>
        <DialogBody>
          <GPTResponseModelSettings
            modelSettings={settings}
            syncAssistant={syncAssistant}
            workspaceId={workspaceId}
            assistantId={assistantId}
          />
        </DialogBody>
      </Dialog>
    </div>
  );
}

export { GPTResponseModel };
