import http from "V2.0/services/httpService";

const base = "/assistants/webhooks";

function getWebhooks({ assistantId, workspaceId }) {
  return http.get(`${base}/whitelabelGetWebhooks/${workspaceId}/${assistantId}`);
}

function postWebhook({payload }) {
  return http.post(`${base}/whitelabelSubscribe/`, payload);
}

function deleteWebhook({ assistantId, workspaceId, webhookId }) {
  return http.delete(
    `${base}/whitelabelUnsubscribe/${workspaceId}/${assistantId}/${webhookId}`
  );
}

export { getWebhooks, postWebhook, deleteWebhook };
