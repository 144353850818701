import { useState } from "react";

import Form from "V2.0/components/Form";
import Joi from "joi-browser";
import { putModelSettings } from "../service";

function PromptSettings({
  modelSettings,
  syncAssistant,
  workspaceId,
  assistantId,
}) {
  const { rawPrompt } = modelSettings?.promptSettings;

  const [inputField] = useState({ rawPrompt });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage] = useState({});

  const template = [
    {
      name: "rawPrompt",
      type: "textArea",
      style: { height: "700px", fontSize: "14px"},
    },
  ];

  const buttonInfo = {
    label: "Save Prompt",
    style: "right-side",
    class:
      "w-full inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none",
  };

  const schema = {
    rawPrompt: Joi.string().max(25000).required().label("Raw prompt"),
  };

  const handleCustomPrompt = async (data: FormData) => {
    const payload = {
      promptSettings: data
    }
    try {
      const response = await putModelSettings({
        workspaceId,
        assistantId,
        payload,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            modelSettings: response.data,
          };
        });
        setLoading(false);
      }
    } catch (e) {
      console.error("Error updating prompt");
      setLoading(false);
    }
  };

  return (
    <div className="px-4 pb-4">
      <Form
        key={JSON.stringify(inputField)}
        errorMessage={errorMessage}
        inputField={inputField}
        template={template}
        schema={schema}
        buttonInfo={buttonInfo}
        onSubmit={handleCustomPrompt}
        loading={loading}
        setLoading={setLoading}
        showRevert={true}
        enableActionsOnChange={true}
      />
    </div>
  );
}

export { PromptSettings };
