import { useState, useEffect } from "react";
import { useOutletContext, useParams, useLocation } from "react-router-dom";
import { VoiceAssistant } from "./Assistant/VoiceAssistant";
import { SmsAssistant } from "./Assistant/SmsAssistant";
import { WhatsappAssistant } from "./Assistant/WhatsappAssistant";
import { EmbedAssistant } from "./Assistant/EmbedAssistant";
import { EmailAssistant } from "./Assistant/EmailAssistant";
import { getAssistant } from "./service";

export enum ASSISTANT {
  VOICE = "voice",
  EMAIL = "email",
  SMS = "sms",
  WHATSAPP = "whatsapp",
  CHAT = "chat",
}

const AssistantComponent = {
  [ASSISTANT.VOICE]: (props) => <VoiceAssistant {...props} />,
  [ASSISTANT.SMS]: (props) => <SmsAssistant {...props} />,
  [ASSISTANT.WHATSAPP]: (props) => <WhatsappAssistant {...props} />,
  [ASSISTANT.EMAIL]: (props) => <EmailAssistant {...props} />,
  [ASSISTANT.CHAT]: (props) => <EmbedAssistant {...props} />,
};

function flatAssistant(assistant) {
  const { assistantSettings, workspace, ...restAssistant } = assistant;

  const { assistantTools, modelSettings, ...restSettings } = assistantSettings;

  const { _id, ...flatAssistant } = {
    assistantId: assistant._id,
    workspaceId: workspace,
    ...restAssistant,
    modelSettings,
    ...assistantTools,
    ...restSettings,
    ...(assistantSettings?.ttsSettings
      ? { ttsSettings: assistantSettings.ttsSettings }
      : {}),
    ...(assistantSettings?.transcriptionSettings
      ? { transcriptionSettings: assistantSettings.transcriptionSettings }
      : {}),
    ...(assistantSettings?.voiceSettings
      ? { voiceSettings: assistantSettings.voiceSettings }
      : {}),
  };

  console.log({ assistant, flatAssistant });
  return flatAssistant;
}

export function useAssistant(assistantId, workspaceId) {
  const [assistant, setAssistant] = useState(null);

  const syncAssistant = (data) => setAssistant(data);
  const syncFlatAssistant = (data) => setAssistant(flatAssistant(data));

  const fetchAssistant = async () => {
    try {
      const response = await getAssistant({
        workspaceId,
        assistantId,
      });
      if (response.status === 200) {
        syncFlatAssistant(response.data);
      }
    } catch (error) {
      console.error("Error getting assistant");
    }
  };

  return {
    assistant,
    syncAssistant,
    syncFlatAssistant,
    fetchAssistant,
  };
}

export function Assistant() {
  const { projectId } = useOutletContext<{ projectId: string }>();
  const { assistantId } = useParams();
  const { assistant, syncAssistant, fetchAssistant } =
    useAssistant(assistantId, projectId);

  useEffect(() => {
    if (assistantId && projectId) {
      fetchAssistant();
    }
  }, [assistantId, projectId]);

  return (
    assistant &&
    AssistantComponent[assistant.assistantType]({
      ...assistant,
      syncAssistant,
    })
  );
}
