import { Field, Label, Description } from "V2.0/common/fieldset";
import { Input } from "V2.0/common/input";

export function DaysToCheckSlotsFor(props) {
  const { daysToCheckSlotsFor, updateDaysToCheckSlotFor } = props;

  return (
    <Field>
      <Label>Days for slots</Label>
      <Description>Number of days to look for an available slot</Description>
      <Input
        type="number"
        min="2"
        max="7"
        value={daysToCheckSlotsFor}
        onChange={(e) => {
          updateDaysToCheckSlotFor(Number(e.target.value));
        }}
      />
    </Field>
  );
}
