import { useState, useRef, useEffect } from "react";
import * as knowledgeService from "V2.0/services/knowledgebaseService";

import Joi from "joi-browser";
import Form from "V2.0/components/Form";
import { message } from "antd";
import { addParagraph } from "../../service";

export function AddTextData({
  projects,
  activeProject,
  projectId,
  KB,
  reload,
  setReload,
  getData,
  refetch,
  onClose,
  kbExists
}) {
  let [count, setCount] = useState(1);

  const [inputFields, setInputFields] = useState({
    description1: "",
    description2: "",
    description3: "",
    description4: "",
    description5: "",
    description6: "",
    description7: "",
    description8: "",
    description9: "",
    description10: "",
    description11: "",
    description12: "",
    description13: "",
    description14: "",
    description15: "",
    description16: "",
    description17: "",
    description18: "",
    description19: "",
    description20: "",
    description21: "",
    description22: "",
    description23: "",
    description24: "",
    description25: "",
    description26: "",
    description27: "",
    description28: "",
    description29: "",
    description30: "",
  });
  const [errorMessage, setErrorMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const ref= useRef({
    current: ""
  });

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({block: "end", behavior: "smooth"});
    }
  }, [count]);

  // Dynamically generate the template based on inputFields
  const template = [
    {
      name: "description1",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 2 && {
      name: "description2",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 3 && {
      name: "description3",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 4 && {
      name: "description4",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 5 && {
      name: "description5",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 6 && {
      name: "description6",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 7 && {
      name: "description7",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 8 && {
      name: "description8",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 9 && {
      name: "description9",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 10 && {
      name: "description10",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 11 && {
      name: "description11",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 12 && {
      name: "description12",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 13 && {
      name: "description13",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 14 && {
      name: "description14",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 15 && {
      name: "description15",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 16 && {
      name: "description16",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 17 && {
      name: "description17",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 18 && {
      name: "description18",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 19 && {
      name: "description19",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 20 && {
      name: "description20",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 21 && {
      name: "description21",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 22 && {
      name: "description22",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 23 && {
      name: "description23",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 24 && {
      name: "description24",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 25 && {
      name: "description25",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 26 && {
      name: "description26",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 27 && {
      name: "description27",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 28 && {
      name: "description28",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 29 && {
      name: "description29",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
    count >= 30 && {
      name: "description30",
      placeholder: "Copy description here",
      type: "textArea",
      style: { height: "300px" },
    },
  ];

  // Joi schema for dynamic validation
  const schema = {
    description1: Joi.string()
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    // description1: Joi.string().regex(/^(([\w\s,."'()-]+)\b[\s,.]*){0,400}$/).options({ language: { string: { regex: { base: 'This section must contain no more than 400 words' } } } }).label("this section"),
    description2: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description3: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description4: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description5: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description6: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description7: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description8: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description9: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description10: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description11: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description12: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description13: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description14: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description15: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description16: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description17: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description18: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description19: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description20: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description21: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description22: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description23: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description24: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description25: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description26: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description27: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description28: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description29: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
    description30: Joi.string()
      .allow(null, "")
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
  };

  // Function to add more input fields dynamically

  const handleSubmit = async (data) => {
    const output = Object.values(data)
      .filter((value) => value !== "")
      .map((value) => ({ value: { data: value } }));

    if (kbExists) {
      const finalData = {paragraphs: output
      };

      try {
        const response = await addParagraph({
          projectId: projects[activeProject]._id,
          knowledgebaseId: KB._id,
          payload: finalData
          });
        if (response.status === 201) {
          message.success("Paragraph added");
          setLoading(false);
          refetch(projects[activeProject]._id, KB._id);
          onClose();
        }
      } catch (ex) {
        if (
          ex.response &&
          ex.response.status >= 400 &&
          ex.response.status < 500
        ) {
          setLoading(false);

          message.error(ex.response.data.msg);
          onClose();
          return;
        }
      }

      return;
    }

    const finalData = {};
    finalData.label = projects[activeProject].name;
    finalData.projectId = projects[activeProject]._id;

    finalData.paragraphs = output;

    try {
      const response = await knowledgeService.createProject(finalData);
      if (response.status === 200) {
        message.success("Paragraph added");
        refetch(projects[activeProject]._id);
        onClose();
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);

        message.error(ex.response.data.msg);
        return;
      }
    }
  };

  const handleAddMore = () => {
    if (count === 30) return message.info("please save before proceeding");
    count = count + 1;
    setCount(count);
  };

  return (
    <div ref={ref} className="px-4">
      <Form
        className="p-4"
        errorMessage={errorMessage}
        inputField={inputFields}
        template={template}
        schema={schema}
        buttonInfo={{
          style: "",
          buttons: "2",
          primaryLabel: "Save",
          secondaryLabel: "Add more",
        }}
        onSubmit={handleSubmit}
        loading={loading}
        setLoading={setLoading}
        handleSecondary={handleAddMore}
        getValue={getData}
      />
    </div>
  );
}
