import { useState, useEffect, useMemo } from "react";
import { Button } from "V2.0/common/button";
import { Field, Label, Description } from "V2.0/common/fieldset";
import { Checkbox, CheckboxField, CheckboxGroup } from "V2.0/common/checkbox";
import { getKnowledgebases } from "V2.0/HomePages/Knowledge_base/service";
import { putRAGSettings } from "../../service";

function KnowledgeBase(props) {
  const {
    ragSettings,
    syncAssistant,
    assistantId,
    workspaceId,
    assistantType,
  } = props;
  const { knowledgeBases } = ragSettings;
  const [availableKnowledgeBases, setAvailableKnowledgeBases] = useState([]);
  const [selectedKnowledgeBases, setSelectedKnowledgeBases] = useState([]);

  useEffect(() => {
    setSelectedKnowledgeBases(knowledgeBases);
  }, [knowledgeBases]);

  const fetchKnowledgeBases = async () => {
    try {
      const response = await getKnowledgebases({ projectId: workspaceId });
      const data = response.data;
      setAvailableKnowledgeBases(data.knowledgebases);
    } catch (e) {
      console.error("Error");
    }
  };

  useEffect(() => {
    if (workspaceId) {
      fetchKnowledgeBases();
    }
  }, [workspaceId]);

  const handleSave = async () => {
    const payload = {
      knowledgeBases: selectedKnowledgeBases,
    };
    try {
      const response = await putRAGSettings({
        workspaceId,
        assistantId,
        payload,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            ragSettings: response.data,
          };
        });
      }
    } catch (e) {
      console.error("Error saving knowledge bases");
    }
  };

  const knowledgeBasesList = useMemo(() => {
    if (availableKnowledgeBases.length > 0) {
      return availableKnowledgeBases.map((kb) => {
        return {
          id: kb._id,
          label: kb.label,
          isSelected: selectedKnowledgeBases.includes(kb._id),
        };
      });
    }
    return [];
  }, [availableKnowledgeBases, selectedKnowledgeBases]);

  return (
    <>
      <div className="flex flex-col w-1/3 mx-auto mt-4 p-4 border rounded-lg">
        <Field>
          <Label>
            <div className="flex justify-between">
              <p className="text-semibold">Available Knowledge Bases</p>
              <Button onClick={handleSave} outline>
                Save
              </Button>
            </div>
          </Label>
          <Description>
            Select from the available list of knowledge bases
          </Description>
          <CheckboxGroup>
            {knowledgeBasesList.map((kb) => {
              let handleChange;

              const unselectKnowledgeBase = () => {
                const idx = selectedKnowledgeBases.findIndex(
                  (_kb) => _kb === kb.id
                );
                setSelectedKnowledgeBases((prevState) => {
                  return prevState.toSpliced(idx, 1);
                });
              };

              const selectKnowledgeBase = () => {
                setSelectedKnowledgeBases((prevState) => {
                  return [...prevState, kb.id];
                });
              };

              handleChange = kb.isSelected
                ? unselectKnowledgeBase
                : selectKnowledgeBase;

              return (
                <CheckboxField key={kb.id}>
                  <Checkbox
                    name={kb.id}
                    checked={kb.isSelected}
                    onChange={handleChange}
                  />
                  <Label>{kb.label}</Label>
                </CheckboxField>
              );
            })}
          </CheckboxGroup>
        </Field>
      </div>
    </>
  );
}

export { KnowledgeBase };
