import { useState } from "react";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { TimeZoneSettings } from "./TimeZoneSettings/TimeZoneSettings";
import { AILabel } from "../MessagingSettings/AILabel";
import { MessageDelay } from "../MessagingSettings/MessageDelay";
import { ParentLayout } from "../AssistantTools/components/ParentLayout";
import { SectionLayout } from "../AssistantTools/components/SectionLayout";
import { SectionItem } from "../AssistantTools/components/SectionItem";
import { MainLayout } from "../AssistantTools/components/MainLayout";

enum SETTINGS {
  TIMEZONE = "Time Zone Settings",
  LABEL_SETTINGS = "Label Settings",
  DELAY_SETTINGS = "Delay Settings"
}

const SettingsComponent = {
  [SETTINGS.TIMEZONE]: (props) => <TimeZoneSettings {...props} />,
  [SETTINGS.LABEL_SETTINGS]: (props) => <AILabel {...props} />,
  [SETTINGS.DELAY_SETTINGS]: (props) => <MessageDelay {...props} />
};

function EmailAssistantSettings(props) {
  const [activeSetting, setActiveSetting] = useState(SETTINGS.TIMEZONE);

  const settings = Object.values(SETTINGS).reduce((acc, setting) => {
    return [
      ...acc,
      { name: setting, href: "#", current: setting === activeSetting },
    ];
  }, []);

  return (
    <ParentLayout>
      <SectionLayout>
        {settings.map((setting) => (
          <SectionItem
            sectionName={setting.name}
            isActive={setting.current}
            onSectionClick={() => setActiveSetting(setting.name)}
          />
        ))}
      </SectionLayout>
      <MainLayout>
        {SettingsComponent[activeSetting]({
          ...props
        })}
      </MainLayout>
    </ParentLayout>
  );
}

export { EmailAssistantSettings };
