import Joi from "joi-browser";
import { ASSISTANT } from "../Assistant";

const fields = {
  transferMessage: {
    template: {
      name: "transferMessage",
      type: "textArea",
      style: { height: "100px" },
      label: "Transfer Message",
    },
    schema: Joi.string().allow(null, ""),
  },
  officeHours: {
    template: {
      name: "officeHours",
      type: "toggle",
      label: "Transfer only during office hours",
    },
    schema: Joi.boolean().required(),
  },
  humanDelay: {
    template: { name: "humanDelay", type: "number", label: "Human Delay" },
    schema: Joi.number().allow(null, ""),
  },
  transferOnMediaMessage: {
    template: {
      name: "transferOnMediaMessage",
      type: "toggle",
      label: "Transfer on Media Message",
    },
    schema: Joi.boolean().required(),
  },
};

const voiceAssistantFields = {
  transferMessage: fields.transferMessage,
  officeHours: fields.officeHours,
};

const smsAssistantFields = {
  transferMessage: fields.transferMessage,
  humanDelay: fields.humanDelay,
};

const whatsappAssistantFields = {
  transferMessage: fields.transferMessage,
  humanDelay: fields.humanDelay,
  officeHours: fields.officeHours,
  transferOnMediaMessage: fields.transferOnMediaMessage,
};

const getTemplateAndSchema = (fields) => {
  return Object.keys(fields).reduce(
    (acc, field) => {
      const { template, schema } = acc;
      const fieldObj = fields[field];
      return {
        template: [...template, fieldObj.template],
        schema: {
          ...schema,
          [field]: fieldObj.schema,
        },
      };
    },
    { template: [], schema: {} }
  );
};

function getInputFields(settings, fields) {
  return Object.keys(fields).reduce((acc, field) => {
    return {
      ...acc,
      [field]: settings[field],
    };
  }, {});
}

function getTransferSettingsByAssistantType(assistantType, settings) {
  switch (assistantType) {
    case ASSISTANT.SMS:
      return {
        ...getTemplateAndSchema(smsAssistantFields),
        inputField: getInputFields(settings, smsAssistantFields),
      };
    case ASSISTANT.VOICE:
      return {
        ...getTemplateAndSchema(voiceAssistantFields),
        inputField: getInputFields(settings, voiceAssistantFields),
      };
    case ASSISTANT.WHATSAPP:
      return {
        ...getTemplateAndSchema(whatsappAssistantFields),
        inputField: getInputFields(settings, whatsappAssistantFields),
      };
    case ASSISTANT.EMAIL:
      return {
        ...getTemplateAndSchema(smsAssistantFields),
        inputField: getInputFields(settings, smsAssistantFields),
      };
    case ASSISTANT.CHAT:
      return {
        ...getTemplateAndSchema(smsAssistantFields),
        inputField: getInputFields(settings, smsAssistantFields),
      };
    default:
      return {
        ...getTemplateAndSchema(smsAssistantFields),
        inputField: getInputFields(settings, smsAssistantFields),
      };
  }
}

export { getTransferSettingsByAssistantType };
