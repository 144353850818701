import { Dialog, DialogBody, DialogTitle } from "V2.0/common/dialog";
import { SMSTriggerForm } from "./SMSTriggerForm";

function SMSTriggerEdit({ smsTrigger, open, onClose, onTriggerEdit }) {

  return (
      <Dialog
        className=""
        size="2xl"
        open={open}
        onClose={onClose}
      >
        <DialogTitle>
          <p className="text-lg">
            Edit SMS Trigger
          </p>
        </DialogTitle>
        <DialogBody className="text-sm/6">
          <SMSTriggerForm
            smsTrigger={smsTrigger}
            onCancel={onClose}
            onSubmit={onTriggerEdit}
          />
        </DialogBody>
      </Dialog>
  );
}

export { SMSTriggerEdit };
