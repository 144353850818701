import { useEffect, useState } from "react";
import { Button } from "V2.0/common/button";
import timezoneData from "V2.0/constants/timezones";
import { putTimezoneSetting } from "../../service";

const scheduleDataDefault = {
  monday: {
    checked: true,
    slots: [{ startTime: "10:00", endTime: "16:00" }],
  },
  tuesday: {
    checked: true,
    slots: [{ startTime: "10:00", endTime: "16:00" }],
  },
  wednesday: {
    checked: true,
    slots: [{ startTime: "10:00", endTime: "16:00" }],
  },
  thursday: {
    checked: true,
    slots: [{ startTime: "10:00", endTime: "16:00" }],
  },
  friday: {
    checked: true,
    slots: [{ startTime: "10:00", endTime: "16:00" }],
  },
  saturday: {
    checked: true,
    slots: [{ startTime: "10:00", endTime: "16:00" }],
  },
  sunday: {
    checked: true,
    slots: [{ startTime: "10:00", endTime: "16:00" }],
  },
};

function TimeZoneSettings(props) {
  const { timezoneSettings, syncAssistant, workspaceId, assistantId } = props;

  const [timezone, setTimezone] = useState("");
  const [daySchedule, setDaySchedule] = useState(scheduleDataDefault);

  useEffect(() => {
    const { _id: timezoneId, timezone, scheduleData } = timezoneSettings;
    const { _id: scheduleDataId, ...daysOfTheWeek } = scheduleData;
    setTimezone(timezone);
    setDaySchedule(daysOfTheWeek);
  }, [timezoneSettings]);

  const handleDayCheckboxChange = (day) => {
    setDaySchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: { ...prevSchedule[day], checked: !prevSchedule[day].checked },
    }));
  };

  const handleTimeChange = (day, timeType, value) => {
    setDaySchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        slots: [{ ...prevSchedule[day]["slots"][0], [timeType]: value }],
      },
    }));
  };

  const handleTimezoneChange = (event) => {
    setTimezone(event.target.value);
  };

  const days = Object.keys(daySchedule);

  const handleSave = async () => {
    const payload = {
      scheduleData: daySchedule,
      timezone,
    };
    try {
      const response = await putTimezoneSetting({
        workspaceId,
        assistantId,
        payload,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            timezoneSettings: response.data,
          };
        });
      }
    } catch (e) {
      console.error("Error updating timezome settings");
    }
  };

  return (
    <div>
      <main className="">
        <div className="w-2/4 mx-auto my-8">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold">Timezone Settings</h3>
          </div>
          <div className="flex flex-col mx-auto my-0">
            <div className="border rounded-lg my-4 p-4">
              <form>
                <>
                  <div className="pb-6 mb-6 border-b border-gray-200 dark:border-gray-700">
                    <label
                      htmlFor="timezones"
                      className="flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      <span className="me-1">Select a timezone</span>
                      <button
                        type="button"
                        data-tooltip-target="tooltip-timezone"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-4 h-4 text-gray-400 cursor-pointer hover:text-gray-900 dark:hover:text-white dark:text-gray-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span className="sr-only">Details</span>
                      </button>
                      <div
                        id="tooltip-timezone"
                        role="tooltip"
                        className="inline-block absolute invisible z-10 py-2 px-3 max-w-sm text-xs font-normal text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                      >
                        Select a timezone that fits your location to accurately
                        display time-related information.
                        <div className="tooltip-arrow" data-popper-arrow></div>
                      </div>
                    </label>
                    <select
                      id="timezones"
                      name="timezone"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={timezone}
                      onChange={handleTimezoneChange}
                      required
                    >
                      <option value="">Choose a timezone</option>
                      {timezoneData.map((timezone) => (
                        <option key={timezone} value={timezone}>
                          {timezone}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-12">
                    {days.map((day) => (
                      <div className="mb-6" key={day}>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center min-w-[4rem]">
                            <input
                              checked={daySchedule[day].checked}
                              id={day}
                              name="days"
                              type="checkbox"
                              value={day}
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              onChange={() => handleDayCheckboxChange(day)}
                            />
                            <label
                              htmlFor={day}
                              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                              {day.charAt(0).toUpperCase() +
                                day.slice(1).substring(0, 2)}
                            </label>
                          </div>
                          <div className="w-full max-w-[7rem]">
                            <label
                              htmlFor={`start-time-${day}`}
                              className="sr-only"
                            >
                              Start time:
                            </label>
                            <div className="relative">
                              <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                                <svg
                                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                              <input
                                type="time"
                                id={`start-time-${day}`}
                                name={`start-time-${day}`}
                                className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                min="09:00"
                                max="18:00"
                                value={daySchedule[day]["slots"][0].startTime}
                                onChange={(e) =>
                                  handleTimeChange(
                                    day,
                                    "startTime",
                                    e.target.value
                                  )
                                }
                                required
                              />
                            </div>
                          </div>
                          <div className="w-full max-w-[7rem]">
                            <label
                              htmlFor={`end-time-${day}`}
                              className="sr-only"
                            >
                              End time:
                            </label>
                            <div className="relative">
                              <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                                <svg
                                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                              <input
                                type="time"
                                id={`end-time-${day}`}
                                name={`end-time-${day}`}
                                className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                min="09:00"
                                max="18:00"
                                value={daySchedule[day]["slots"][0].endTime}
                                onChange={(e) =>
                                  handleTimeChange(
                                    day,
                                    "endTime",
                                    e.target.value
                                  )
                                }
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              </form>
              <div className="flex justify-end">
                <Button onClick={() => handleSave()}>Save</Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export { TimeZoneSettings };
