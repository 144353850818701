import React, { useState } from "react";
import { message } from "antd";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as knowledgebaseService from "../../services/knowledgebaseService";

function AddSpreadsheet({ projects, activeProject, onClose, refetch }) {
  const [inputField] = useState({
    url: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "url",
      label: "Google Sheet URL",
      description: `Please make sure the Google sheet is public`,
      type: "text",
      placeholder: "Paste here",
    },
  ];

  const buttonInfo = { label: "Add Sheet", style: "entire-width" };
  // const buttonInfo1 = { label: " Create A", style: "" };

  const schema = {
    url: Joi.string().uri().min(2).max(500).required().label("URL"),
  };

  const handleSubmit = async (data) => {
    {
      try {
        const response = await knowledgebaseService.addWhitelabelGSheet(
          projects[activeProject]._id,
          data
        );
        if (response.status === 200) {
          message.success("Success. Your file has now been queued.");
          refetch(projects[activeProject]._id);
          onClose();
          setLoading(false);
        }
      } catch (ex) {
        if (
          ex.response &&
          ex.response.status >= 400 &&
          ex.response.status < 500
        ) {
          setLoading(false);
          message.error(ex.response.data.msg);
          return;
        }
      }
    }
  };

  return (
    <Form
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={buttonInfo}
      onSubmit={handleSubmit}
      loading={loading}
      setLoading={setLoading}
    />
  );
}

export default AddSpreadsheet;
