import { useState } from "react";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { TimeZoneSettings } from "./TimeZoneSettings/TimeZoneSettings";
import { MessageDelay } from "../MessagingSettings/MessageDelay";

enum SETTINGS {
  TIMEZONE = "Time Zone Settings",
  DELAY = "Delay Settings"
}

const SettingsComponent = {
  [SETTINGS.TIMEZONE]: (props) => <TimeZoneSettings {...props} />,
  [SETTINGS.DELAY]: (props) => <MessageDelay {...props} />
};

function SectionItem(props) {
  const { sectionName, onSectionClick, isActive, icon } = props;
  return (
    <div
      onClick={onSectionClick}
      className={`w-full h-16 flex items-center justify-between px-4 hover:bg-gray-300 hover:cursor-pointer ${
        isActive ? "bg-gray-200" : ""
      }`}
    >
      <div className="flex items-center gap-x-2">
        {icon && icon}
        <p className="font-semibold text-sm">{sectionName}</p>
      </div>
      <div className="flex justify-end">
        <ChevronRightIcon className="size-5 text-gray-400" />
      </div>
    </div>
  );
}

function WhatsappAssistantSettings(props) {
  const [activeSetting, setActiveSetting] = useState(SETTINGS.TIMEZONE);

  const settings = Object.values(SETTINGS).reduce((acc, setting) => {
    return [
      ...acc,
      { name: setting, href: "#", current: setting === activeSetting },
    ];
  }, []);

  return (
    <div className="flex">
      <div className="w-1/4">
        {settings.map((setting) => (
          <SectionItem
            sectionName={setting.name}
            isActive={setting.current}
            onSectionClick={() => setActiveSetting(setting.name)}
          />
        ))}
      </div>
      <div className="w-3/4">
        {SettingsComponent[activeSetting]({
         ...props
        })}
      </div>
    </div>
  );
}

export { WhatsappAssistantSettings };
