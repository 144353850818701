import {
  deleteCommunicationBinding,
  putCommunicationBinding,
} from "../service";

function useCommunicationBinding({ syncAssistant, assistantId, workspaceId }) {
  const addCommunicationBinding = async (payload) => {
    try {
      const response = await putCommunicationBinding({
        workspaceId,
        assistantId,
        payload,
      });
      if (response.status === 201) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            communicationBinding: [
              ...prevAssistant.communicationBinding,
              response.data.binding,
            ],
          };
        });
        return;
      }
    } catch (e) {
      console.error("Error in updating communication binding");
    }
  };

  const removeCommunicationBinding = async (communicationBindingId) => {
    try {
      const response = await deleteCommunicationBinding({
        workspaceId,
        assistantId,
        communicationBindingId,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            communicationBinding: response.data.communicationBinding,
          };
        });
        return;
      }
    } catch (e) {
      console.error("Error deleting communication binding");
    }
  };

  return { addCommunicationBinding, removeCommunicationBinding };
}

export { useCommunicationBinding };
