import { useNavigate } from "react-router-dom";
import { TableComponent } from "V2.0/HomePages/HomeModule/CustomAPIs/components/TableComponent";
import { RiWhatsappFill } from "react-icons/ri";
import {
  EnvelopeIcon,
  ChatBubbleBottomCenterTextIcon,
  GlobeAltIcon,
  PhoneArrowUpRightIcon,
  ChatBubbleLeftIcon,
} from "@heroicons/react/24/solid";

export const Icons = {
  whatsapp: <RiWhatsappFill className="size-5 text-green-500" />,
  chat: <GlobeAltIcon className="size-6 text-pink-500" />,
  sms: <ChatBubbleBottomCenterTextIcon className="size-5 text-indigo-600" />,
  voice: <PhoneArrowUpRightIcon className="size-5 text-amber-500" />,
  email: <EnvelopeIcon className="size-5 text-blue-600" />,
};

function SourceIcon({ children }) {
  return (
    <div className="max-h-10 max-w-10 bg-white rounded-lg border flex justify-center items-center p-2">
      {children}
    </div>
  );
}

const getAssistantIcon = (type) => {
  return <SourceIcon>{Icons[type]}</SourceIcon>;
};

const assistantHeaders: { key: string; label: string; format?: any }[] = [
  {
    key: "assistantType",
    label: "Type",
    format: getAssistantIcon,
  },
  {
    key: "name",
    label: "Assistant Name",
  },
  {
    key: "description",
    label: "Description",
  },
];

export function AssistantsTable(props) {
  const { assistants, onDelete } = props;
  const navigate = useNavigate();

  return (
    <TableComponent
      headers={assistantHeaders}
      data={assistants}
      onRowClick={(item) =>
        navigate(`/assistants/${item._id}`, { state: { assistant: item } })
      }
      onDelete={(item) => onDelete(item)}
    />
  );
}
