import { putVoiceSettings } from "../service";

function useVoiceSettings({ syncAssistant, workspaceId, assistantId }) {
  const updateVoiceSettings = async (payload) => {
    try {
      const response = await putVoiceSettings({
        workspaceId,
        assistantId,
        payload,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            voiceSettings: response.data,
          };
        });
      }
    } catch (e) {
      console.error("Error updating voice settings");
    }
  };
  return { updateVoiceSettings };
}

export { useVoiceSettings };
