import Joi from "joi-browser";

const commonFields = [
  {
    name: "actionName",
    label: "Name of the event",
    type: "text",
  },
  {
    name: "phoneNumber",
    label: "To number",
    type: "text",
    description:
      "Leave it blank if you want to automatically pick the number from the call.",
  },
  {
    name: "triggerEvent",
    label: "Trigger Event",
    type: "select",
    options: [
      { id: 1, value: "callStart", label: "Start of the call" },
      { id: 2, value: "duringCall", label: "During the call" },
      { id: 3, value: "callEnd", label: "End of the call" },
    ],
  },
  {
    name: "contentText",
    label: "SMS content",
    type: "textArea",
    style: { height: "100px" },
  },
];

const commonSchema = {
  actionName: Joi.string().required().max(50).label("Event name"),
  phoneNumber: Joi.string()
    .allow(null, "")
    .required()
    .max(300)
    .label("To number"),
  trigger: Joi.string().allow(null, "").max(1500).label("Description"),
  triggerEvent: Joi.string().required().label("Trigger Event"),
  contentText: Joi.string().max(1500).label("SMS Content"),
};

const descriptionFieldRequiredSchema = {
  actionName: Joi.string().required().max(50).label("Event name"),
  phoneNumber: Joi.string()
    .allow(null, "")
    .required()
    .max(300)
    .label("To number"),
  trigger: Joi.string().required().max(1500).label("Description"),
  triggerEvent: Joi.string().required().label("Trigger Event"),
  contentText: Joi.string().max(1500).label("SMS Content"),
};

const descriptionFieldTemplate = [
  {
    name: "actionName",
    label: "Name of the event",
    type: "text",
  },
  {
    name: "phoneNumber",
    label: "To number",
    type: "text",
    description:
      "Leave it blank if you want to automatically pick the number from the call.",
  },
  {
    name: "triggerEvent",
    label: "Trigger Event",
    type: "select",
    options: [
      { id: 1, value: "callStart", label: "Start of the call" },
      { id: 2, value: "duringCall", label: "During the call" },
      { id: 3, value: "callEnd", label: "End of the call" },
    ],
  },
  {
    name: "trigger",
    label: "Describe when do you want to send the SMS",
    type: "textArea",
    style: { height: "150px" },
  },
  {
    name: "contentText",
    label: "SMS content",
    type: "textArea",
    style: { height: "100px" },
  },
];

export {
  commonFields,
  commonSchema,
  descriptionFieldRequiredSchema,
  descriptionFieldTemplate,
};
