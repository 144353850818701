import { useState } from "react";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { WhitelistDomain } from "../MessagingSettings/WhitelistDomain";
import { ParentLayout } from "../AssistantTools/components/ParentLayout";
import { SectionLayout } from "../AssistantTools/components/SectionLayout";
import { SectionItem } from "../AssistantTools/components/SectionItem";
import { MainLayout } from "../AssistantTools/components/MainLayout";

enum SETTINGS {
//   TIMEZONE = "Time Zone Settings",
  WHITELIST_DOMAIN = "Whitelist domain"
}

const SettingsComponent = {
//   [SETTINGS.TIMEZONE]: (props) => <TimeZoneSettings {...props} />,
  [SETTINGS.WHITELIST_DOMAIN]: (props) => <WhitelistDomain {...props} />
};

function EmbedAssistantSettings(props) {
  const [activeSetting, setActiveSetting] = useState(SETTINGS.WHITELIST_DOMAIN);

  const settings = Object.values(SETTINGS).reduce((acc, setting) => {
    return [
      ...acc,
      { name: setting, href: "#", current: setting === activeSetting },
    ];
  }, []);

  return (
    <ParentLayout>
      <SectionLayout>
        {settings.map((setting) => (
          <SectionItem
            sectionName={setting.name}
            isActive={setting.current}
            onSectionClick={() => setActiveSetting(setting.name)}
          />
        ))}
      </SectionLayout>
      <MainLayout>
        {SettingsComponent[activeSetting]({
          ...props
        })}
      </MainLayout>
    </ParentLayout>
  );
}

export { EmbedAssistantSettings };
