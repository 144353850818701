import http from "./httpService";
// import { apiUrl } from "../config.json";

// const apiEndpoint = apiUrl + "/companies";
const apiEndpoint = "/workspace";

/**--------- Whitelabel---------------------* */

export function getAllProjectsForCustomerId() {
  return http.get(apiEndpoint + "/company/whitelabelCompany");
}

export function deleteProject({projectId}) {
  return http.post(`adminui/whitelabelDeleteProject/${projectId}`);
}


export function createProject(obj,companyId, type ) {

  return http.post(apiEndpoint + "/whitelabelCreateWorkspace", {
    name: obj.title,
    companyId: companyId, 
    type:type,
    projectExtractionLimit: obj.projectExtractionLimit
  });
}

export function getSettings(projectId) {
  return http.get(apiEndpoint + "/whitelabelSettings/"+ projectId);
}



export function createExtraction(projectId, req) {
  return http.post(apiEndpoint + "/" + "whitelabelCreateExtraction"+ "/" + projectId, req);
}

export function getExtraction(projectId) {
  return http.get(apiEndpoint + "/settings" + "/whiteLabelExtractionParameters"+ "/" + projectId);
}


export function updateProject(projectId, req) {
  return http.put(apiEndpoint + "/whitelabelUpdateWorkspace/" + projectId, req, {
    // headers: {
    //   'Content-Type': 'application/json'
    // }
  });
}

/*---------------------------------------------------*/

export function updatePrompt(projectId,key ) {

  return http.put(apiEndpoint + "/" + projectId, {
    key: key, 
  });
}

 export function getAllProjects(companyId) {
  return http.get(apiEndpoint + "/company/"+ companyId);
}


export function getPosts(projectId) {
  return http.get(apiEndpoint + "/settings/workflowquestions/"+ projectId);
}






