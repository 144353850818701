import http from "V2.0/services/httpService";

const endPoint: string = "/knowledgebase";

function getKnowledgebases({ projectId }) {
  return http.get(`${endPoint}/whitelabelKbList/${projectId}`);
}

function createKnowledgebase({
  projectId,
  payload,
}: {
  projectId: string;
  payload: { label: string };
}) {
  return http.post(`${endPoint}/whitelabelCreateKnowledgeBase/${projectId}`, payload);
}

function deleteKnowledgebase({
  projectId,
  _id,
}: {
  projectId: string;
  _id: string;
}) {
  return http.delete(`${endPoint}/whitelabelDeleteKnowlegeBase/${projectId}/${_id}`);
}

function getKnowledgebase({ projectId, knowledgebaseId }) {
  return http.get(
    `${endPoint}/whitelabelGetKnowledgebase/${projectId}/${knowledgebaseId}`
  );
}

function addParagraph({ projectId, knowledgebaseId, payload }) {
  return http.post(
    `${endPoint}/whitelabelAddParagraph/${projectId}/${knowledgebaseId}`,
    payload
  );
}

function updateParagraph({ projectId, knowledgebaseId, payload }) {
  return http.put(
    `${endPoint}/whitelabelUpdateParagraph/${projectId}/${knowledgebaseId}`,
    payload
  );
}

function deleteParagraph({ projectId, knowledgebaseId, payload }) {
  return http.delete(
    `${endPoint}/whitelabelDeleteParagraph/${projectId}/${knowledgebaseId}`,
    payload
  );
}

function addURLToKB(workspaceId: string, kbId: string, obj: any) {
  return http.post(`${endPoint}/whitelabelUrlist/${workspaceId}/${kbId}`, obj);
}


function docsUpload(workspaceId: string, kbId: string, formData) {
  return http.post(`${endPoint}/whitelabelUpload/${workspaceId}/${kbId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function addGSheet(
  kbId: string,
  workspaceId: string,
  payload: { url: string }
) {
  // const params = new URLSearchParams({
  //   url: url,
  // })

  return http.post(`${endPoint}/whitelabelAddSheet/${workspaceId}/${kbId}`, payload);
}

function syncGSheet(
  kbId: string,
  workspaceId: string,
  payload: { eTag: string }
) {
  return http.post(`${endPoint}/whitelabelSyncSheet/${workspaceId}/${kbId}`, payload);
}

function deleteCorpus(kbId: string, workspaceId: string, eTag: string) {
  return http.delete(`${endPoint}/whitelabelDeleteCorpus/${workspaceId}/${kbId}/${eTag}`);
}

function getDocumentDownloadLink(workspaceId, kbId, etag) {
  return http.get(
    `${endPoint}/whitelabelGetDocumentDownloadLink/${workspaceId}/${kbId}/${etag}`
  );
}

export {
  getKnowledgebases,
  getKnowledgebase,
  createKnowledgebase,
  deleteKnowledgebase,
  addParagraph,
  updateParagraph,
  deleteParagraph,
  addURLToKB,
  docsUpload,
  syncGSheet,
  addGSheet,
  deleteCorpus,
  getDocumentDownloadLink,
};
