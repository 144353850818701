import { useState } from "react";

import { Button } from "V2.0/common/button";
import { Dialog, DialogTitle, DialogBody } from "V2.0/common/dialog";
import { DialogTitleWithClose } from "V2.0/components/DialogTitleWithClose";
import { TTSSettings } from "./TTSSettings";
import { PencilIcon } from "@heroicons/react/24/outline";

const voices = {
  Eva: { label: "Eva (Female, English US)" },
  Cindy: { label: "Cindy (Female,English)" },
  Arthor: { label: "Arthor (English US, Male)" },
  Antoine: { label: "Antoine (Female, French)" },
  Andy: { label: "Andy (Male, English US)" },
  Liz: { label: "Liz (Female, English US)" },
  Brendan: { label: "Brendan (Male, English US)" },
  Sonia: { label: "Sonia (Female, English UK)" },
  Max: { label: "Max (Male, German)" },
  Felix: { label: "Felix (Male, German)" },
  Aurora: { label: "Aurora (Female, Italian)" },
  Marco: { label: "Marco (Male, Italian)" },
  Giovanni: { label: "Giovanni (Male, Italian)" },
  Martina: { label: "Martina (Female, Italian)" },
  Leonardo: { label: "Leonardo (Male, Italian)" },
  Sofia: { label: "Sofia (Female, Italian)" },
  Nat: { label: "Nat (Male, English AU)" },
  Ann: { label: "Ann (Female, English AU)" },
  Jo: { label: "Jo (Female, English AU)" },
  Will: { label: "Will (Male, English AU)" },
  Elise: { label: "Elise (Female, English AU)" },
  Dunn: { label: "Dunn (Male, English AU)" },
  Sharma: { label: "Sharma (Male, Hindi)" },
  Shantanu: { label: "Shantanu (Male, Hindi)" },
  Vikram: { label: "Vikram (Male, Hindi - Devanagri Script Only)" },
  Kavya: { label: "Kavya (Female, Hindi)" },
  Sindhu: { label: "Sindhu (Female, Hindi)" },
  Neha: { label: "Neha (Female, Hindi)" },
  Afonso: { label: "Afonso (Male, Portuguese)" },
  Artur: { label: "Artur (Male, Brazilian Portuguese)" },
  Evora: { label: "Evora (Female, Brazilian Portuguese)" },
  Cassandra: { label: "Cassandra (Female, Brazilian Portuguese)" },
  Lisa: { label: "Lisa (Female, English US)" },
  Cart: { label: "Cart (Male, English US)" },
  Kyle: { label: "Kyle (Male, English US)" },
  Charlotte: { label: "Charlotte (Female, French)" },
  Alain: { label: "Alain (Male, French)" },
  Juliette: { label: "Juliette (Female, French)" },
  Louis: { label: "Louis (Male, French)" },
  Charles: { label: "Charles (Male, French)" },
  Lucia: { label: "Lucia (Female, Spanish)" },
  Diego: { label: "Diego (Male, Spanish)" },
  Maria: { label: "Maria (Female, Spanish)" },
  Jose: { label: "Jose (Male, Spanish)" },
  Juan: { label: "Juan (Male, Spanish)" },
  Felipe: { label: "Felipe (Male, Spanish)" },
  Fernanda: { label: "Fernanda (Female, Spanish)" },
  Juan: { label: "Juan (Male, Argentinian Spanish)" },
  Ines: { label: "Ines (Female, Argentinian Spanish)" },
  Sophia: { label: "Sophia (Female, English US)" },
  Ignacio: { label: "Ignacio (Male, English US)" },
  Britt: { label: "Britt (Female, English)" },
  Marcus: { label: "Marcus (Male, English)" },
  Cynthia: { label: "Cynthia (Female, English)" },
  Cath: { label: "Cath (Female, English)" },
  Nick: { label: "Nick (Male, English)" },
  Jean: { label: "Jean (Male, French)" },
  Madeleine: { label: "Madeleine (Female, French)" },
  Joelle: { label: "Joelle (Female, French)" },
  Camille: { label: "Camille (Female, French)" },
  Celine: { label: "Celine (Female, French)" },
  Jacques: { label: "Jacques (Female, French)" },
  Jan: { label: "Jan Schevenels (Male, Flemish)" },
  Petra: { label: "Petra (Female, Flemish)" },
  HansC: { label: "Hans (Male, Flemish)" },
  Ben: { label: "Ben van Praag (Male, Flemish)" },
  Christian: { label: "Christian - Nieuws Stijl (Male, Flemish)" },
  Walter: { label: "Walter (Male, Flemish)" },
  Bart: { label: "Bart(Male, Flemish)" },
  Adrien: { label: "Adrien (Male, French(Belgie))" },
  Christophe: { label: "Christophe (Male, French(Belgie))" },
};

function TTS(props) {
  const { settings, syncAssistant, assistantId, workspaceId } = props;
  const [open, setOpen] = useState(false);
  const inputField = {
    voiceId: settings?.voiceId || voices.Cindy,
  };

  const voice = voices[inputField.voiceId]?.label;

  return (
    <div className="flex items-center border rounded-lg">
      <div className="px-2 border-r">
        <p className="text-sm">{inputField && voice}</p>
      </div>
      <Button plain onClick={() => setOpen(true)}>
        <PencilIcon />
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <DialogTitleWithClose
            title={"Select Voice"}
            onClose={() => setOpen(false)}
          />
        </DialogTitle>
        <DialogBody>
          <TTSSettings
            settings={inputField}
            syncAssistant={syncAssistant}
            assistantId={assistantId}
            workspaceId={workspaceId}
          />
        </DialogBody>
      </Dialog>
    </div>
  );
}

export { TTS };
