import { useState } from "react";
import { Dialog, DialogBody, DialogTitle } from "V2.0/common/dialog";
import { Button } from "V2.0/common/button";
import { PlusIcon } from "@heroicons/react/24/outline";
import { ExtractionFieldForm } from "./ExtractionFieldForm";

function ExtractionFieldAdd(props) {
  const {
    open,
    onAddField,
    onConfirmAddField,
    onClose
  } = props;

  return (
    <>
      <Button outline onClick={onAddField}>
        <PlusIcon /> Add Field
      </Button>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Add Field</DialogTitle>
        <DialogBody>
          <ExtractionFieldForm
            onSubmit={onConfirmAddField}
            handleSecondary={onClose}
            item=""
          />
        </DialogBody>
      </Dialog>
    </>
  );
}

export { ExtractionFieldAdd };
