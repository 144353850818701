import {
  Dialog,
  DialogTitle,
  DialogBody,
  DialogActions,
} from "V2.0/common/dialog";
import { Button } from "V2.0/common/button";

export function CommunicationBindingDelete(props) {
  const { open, onClose, onDelete, title } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title} </DialogTitle>
      <DialogActions>
        <Button outline onClick={onClose}>Cancel</Button>
        <Button onClick={onDelete}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}
