import { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "V2.0/components/Form";
import * as voiceapiservice from "V2.0/services/voiceAPIServices";
import { message } from "antd";
import { Dialog, DialogTitle, DialogBody } from "V2.0/common/dialog";
import { DialogTitleWithClose } from "V2.0/components/DialogTitleWithClose";

function TestVoice({ projectId, open, onClose, assistantId, fromNumbers }) {
  const [inputField, setInputField] = useState({
    fromNumber: "",
    toNumber: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "fromNumber",
      label: "From Number",
      description:
        "Always start your number with the country code. Ensure that your Twilio account is connected before placing a call.",
      type: "select",
      placeholder: "+1123456789",
      options: [
        { id: 0, label: "(None Selected)", value: "" },
        ...fromNumbers.map((num, idx) => {
          return { id: idx + 1, label: num, value: num };
        }),
      ],
    },
    {
      name: "toNumber",
      label: "To Number",
      description:
        "Always start your number with the country code. Ensure that your Twilio account is connected before placing a call.",
      type: "text",
      placeholder: "+1123456789",
    },
  ];

  const buttonInfo = { label: "Send Demo call", style: "right-side" };

  const schema = {
    fromNumber: Joi.string().required().label("From Number"),
    toNumber: Joi.string().required().label("To Number"),
  };

  const handleCall = async (data) => {
    try {
      const response = await voiceapiservice.placeVoiceCall({
        workspaceId: projectId,
        assistantId,
        payload: data,
      });
      if (response.status === 200) {
        message.success("We have made the call. You will receive it soon.");
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false); // Ensure setLoading is defined and used correctly
        message.error(ex.response.data.msg);
        setLoading(false);
      }
    }
  };

  return (
    open && (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          <DialogTitleWithClose title="Talk with Assistant" onClose={onClose} />
        </DialogTitle>
        <DialogBody>
          <Form
            key={JSON.stringify(inputField)}
            errorMessage={errorMessage}
            inputField={inputField}
            template={template}
            schema={schema}
            onSubmit={handleCall}
            loading={loading}
            setLoading={setLoading}
            // getValue ={getInputValue}
            buttonInfo={buttonInfo}
          />
        </DialogBody>
      </Dialog>
    )
  );
}

export { TestVoice };
