import { useState } from "react";

import { InactivitySettings } from "../VoiceSettings";
import { InterruptionSettings } from "../VoiceSettings";
import { WelcomeMessageSettings } from "../VoiceSettings";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { TimeZoneSettings } from "./TimeZoneSettings/TimeZoneSettings";
import { ParentLayout } from "../AssistantTools/components/ParentLayout";
import { SectionLayout } from "../AssistantTools/components/SectionLayout";
import { MainLayout } from "../AssistantTools/components/MainLayout";
import { SectionItem } from "../AssistantTools/components/SectionItem";

enum SETTINGS {
  WELCOME_MESSAGE = "Welcome Message",
  INACTIVITY = "Inactivity Settings",
  INTERRUPTION = "Interruption Settings",
  TIMEZONE = "Timezone Settings",
}

const SettingsComponent = {
  [SETTINGS.INACTIVITY]: (props) => <InactivitySettings {...props} />,
  [SETTINGS.INTERRUPTION]: (props) => <InterruptionSettings {...props} />,
  [SETTINGS.WELCOME_MESSAGE]: (props) => <WelcomeMessageSettings {...props} />,
  [SETTINGS.TIMEZONE]: (props) => <TimeZoneSettings {...props} />,
};

function VoiceAssistantSettings(props) {
  const {
    voiceSettings,
    timezoneSettings,
    syncAssistant,
    workspaceId,
    assistantId,
  } = props;

  const [activeSetting, setActiveSetting] = useState(SETTINGS.WELCOME_MESSAGE);

  const settings = Object.values(SETTINGS).reduce((acc, setting) => {
    return [
      ...acc,
      { name: setting, href: "#", current: setting === activeSetting },
    ];
  }, []);

  return (
    <ParentLayout>
      <SectionLayout>
        {settings.map((setting) => (
          <SectionItem
            sectionName={setting.name}
            isActive={setting.current}
            onSectionClick={() => setActiveSetting(setting.name)}
          />
        ))}
      </SectionLayout>
      <MainLayout>
        {SettingsComponent[activeSetting]({
          ...voiceSettings,
          timezoneSettings,
          syncAssistant,
          workspaceId,
          assistantId,
        })}
      </MainLayout>
    </ParentLayout>
  );
}

export { VoiceAssistantSettings };
