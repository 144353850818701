import { message } from "antd";
import { useState } from "react";
import { Button } from "V2.0/common/button";
import {
  Dialog,
  DialogTitle,
  DialogBody,
  DialogActions,
} from "V2.0/common/dialog";

function TransferAgentDelete(props) {
  const { deleteAgent, open, onClose } = props;
  const [loading, setLoading] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    deleteAgent()
      .then(() => {
        setLoading(false);
        onClose();
        message.success("Agent deleted");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Agent?</DialogTitle>
      <DialogActions>
        <Button outline onClick={onClose}>Cancel</Button>
        <Button loading={loading} onClick={handleDelete}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

export { TransferAgentDelete };
