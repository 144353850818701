import { Button } from "V2.0/common/button";
import { Dialog, DialogTitle, DialogActions } from "V2.0/common/dialog";

export function CustomActionDelete(props) {
  const { open, onClose, onDelete } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Trigger</DialogTitle>
      <DialogActions>
        <Button outline onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onDelete}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}
