import React from "react";

import { useForm } from "../../components/Form/useForm";
import Form from "../../components/Form";

import { Dialog, DialogTitle, DialogActions, DialogBody } from "V2.0/common/dialog";
import { Button } from "V2.0/common/button";

import { returnParamSchema } from "../models/schema";
import { ReturnParamsTemplate } from "../models/template";
import { DialogTitleWithClose } from "../../components/Dialog/DialogTitleWithClose";

export function EditReturnParam({ isOpen, onClose, updateReturnParam, param }) {
    const { _id, ...returnParam } = param;
  
    const { data, validate, error, handleChange, setError } = useForm(
      returnParam,
      returnParamSchema
    );
  
    const onSave = () => {
      const errors = validate(data, returnParamSchema);
  
      if (errors) {
        setError(errors);
        return;
      }
  
      updateReturnParam({ ...data, _id });
      onClose();
    };
  
    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          <DialogTitleWithClose title="Edit Return param" onClose={onClose} />
        </DialogTitle>
        <DialogBody>
          <Form
            key={JSON.stringify(returnParam)}
            data={data}
            template={ReturnParamsTemplate}
            error={error}
            handleChange={handleChange}
          />
        </DialogBody>
        <DialogActions>
          <Button onClick={onClose} outline>Cancel</Button>
          <Button onClick={onSave}>Save</Button>
        </DialogActions>
      </Dialog>
    );
  }