import { TableComponent } from "V2.0/HomePages/HomeModule/CustomAPIs/components/TableComponent";

const headers: { key: string; label: string }[] = [
  {
    key: "identifier",
    label: "Number",
  },
  {
    key: "isActive",
    label: "Status",
  },
];

function PhoneNumberTable(props) {
  const { communicationBinding, onItemDelete } = props;

  return (
    <TableComponent
      headers={headers}
      data={communicationBinding}
      onDelete={onItemDelete}
    />
  );
}

export { PhoneNumberTable };
