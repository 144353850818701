import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../common/dropdown";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../common/table";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import * as knowledgeService from "../../services/knowledgebaseService";
import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
} from "../../common/alert";
import { Button } from "../../common/button";
import { message } from "antd";
import Empty from "../../images/empty.svg";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Dialog, DialogBody, DialogTitle } from "V2.0/common/dialog";
import { DialogTitleWithClose } from "../HomeModule/CustomAPIs/components/Dialog/DialogTitleWithClose";
import AddSpreadsheet from "../HomeKBComponent/AddSpreadsheet";
import { deleteCorpus, syncGSheet } from "./service";

function AddSpreadSheet(props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button outline onClick={() => setOpen(true)}>
        <PlusIcon />
        Add Spreadsheet
      </Button>
      <Dialog size="xl" open={open} onClose={() => {}}>
        <DialogTitle>
          <DialogTitleWithClose
            title="Add Spreadsheet"
            onClose={() => setOpen(false)}
          />
        </DialogTitle>
        <DialogBody>
          <AddSpreadsheet {...props} onClose={() => setOpen(false)} />
        </DialogBody>
      </Dialog>
    </>
  );
}

function Spreadsheets({
  KB,
  projects,
  activeProject,
  reload,
  setReload,
  refetch,
}) {
  let [isOpen, setIsOpen] = useState(false);
  const [eTag, setEtag] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await deleteCorpus(
        KB._id,
        projects[activeProject]._id,
        eTag
      );

      if (response.status === 200) {
        refetch(projects[activeProject]._id, KB._id);
        setIsOpen(false);
        setLoading(false);
        setReload(!reload);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
        setIsOpen(false);
        setLoading(false);
        return;
      }
    }
  };

  const handleGoogleSheetSync = async (item) => {
    setLoading(true);
    let data = {};
    // data.url = item.location;
    // data.template = "general";
    data.eTag = item.eTag;

    try {
      const response = await syncGSheet(
        KB._id,
        projects[activeProject]._id,
        data
      );

      if (response.status === 200) {
        message.success("The information will be updated shortly.");
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
        return;
      }
    }
  };

  const showDeleteModal = (etag) => {
    setIsOpen(true);
    setEtag(etag);
  };

  const Skeleton = () => {
    return (
      <div className="animate-pulse">
        <div className="h-4 bg-gray-200 rounded"></div>
      </div>
    );
  };

  return (
    <div className="flex flex-col max-h-[85dvh] p-8 gap-y-4">
      <div className="flex justify-between pb-4 border-b">
        <p className="text-xl font-semibold">Spreadsheets</p>
        <AddSpreadSheet
          KB={KB}
          projects={projects}
          activeProject={activeProject}
          refetch={refetch}
        />
      </div>
      <div className="border rounded-lg h-full p-4">
        <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] max-h-[80dvh] overflow-scroll">
          <TableHead>
            <TableRow>
              <TableHeader>Data Type</TableHeader>
              <TableHeader>Content</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Actions</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {KB && KB.corpus ? (
              KB.corpus.filter((corpus) => corpus.type === "google_sheet")
                .length > 0 ? (
                KB.corpus
                  .filter((corpus) => corpus.type === "google_sheet")
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="font-medium">{item.type}</TableCell>
                      <TableCell
                        className="truncate"
                        style={{ maxWidth: "200px" }}
                      >
                        {item.location}
                      </TableCell>
                      <TableCell className="text-zinc-500">
                        {item.state}
                      </TableCell>
                      <TableCell>
                        <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                          <Dropdown>
                            <DropdownButton plain aria-label="More options">
                              <EllipsisHorizontalIcon />
                            </DropdownButton>
                            <DropdownMenu anchor="bottom end">
                              <DropdownItem
                                onClick={() => showDeleteModal(item.eTag)}
                              >
                                Delete
                              </DropdownItem>

                              <DropdownItem
                                onClick={() => handleGoogleSheetSync(item)}
                              >
                                Sync
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} className="text-center">
                    <div className="flex flex-col items-center justify-center m-5">
                      <img src={Empty} alt="No files" className="w-16 h-16" />
                      <p>You might not have uploaded any Spreadsheet yet.</p>
                    </div>
                  </TableCell>
                </TableRow>
              )
            ) : (
              [...Array(3)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      <Alert open={isOpen} onClose={setIsOpen}>
        <AlertTitle>Delete Spreadsheet</AlertTitle>
        <AlertDescription>
          By deleting this file, your project will no longer have access to this
          knowledge.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>
            {loading ? "Please wait..." : "Delete"}
          </Button>
        </AlertActions>
      </Alert>
    </div>
  );
}

export default Spreadsheets;
