import { useState } from "react";
import { getthirdpartyData } from "V2.0/services/thirdpartyService";

function useCommunicationChannel({ workspaceId }) {
  const [communicationChannel, setCommunicationChannel] = useState({
    phoneNumbers: [],
  });

  const fetchCommunicationChannels = async () => {
    try {
      const response = await getthirdpartyData(workspaceId);
      if (response.status === 200) {
        const { telephonySettings } = response.data;
        if (telephonySettings.telephonyProvider === "twilio") {
          setCommunicationChannel({
            phoneNumbers: telephonySettings.twilioSettings.phoneNumbers,
          });
        }

        if (telephonySettings.telephonyProvider === "plivo") {
          setCommunicationChannel({
            phoneNumbers: telephonySettings.plivoSettings.phoneNumbers,
          });
        }
      }
    } catch (e) {
      console.error("Error getting third party data");
    }
  };

  return {
    communicationChannel,
    fetchCommunicationChannels,
  };
}

export { useCommunicationChannel };
