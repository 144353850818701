import { useState } from "react";
import Joi from "joi-browser";
import Form from "V2.0/components/Form";
import { useMessagingSettings } from "./useMessagingSettings";

const template = [{ name: "aiLabel", label: "AI Label", type: "text" }];

const schema = {
  aiLabel: Joi.string().required(),
};

const buttonInfo = {
  label: "Save",
  style: "entire-width",
};

function AILabel(props) {
  const { messagingSettings, syncAssistant, workspaceId, assistantId } = props;
  const { updateMessagingSettings } = useMessagingSettings({
    syncAssistant,
    workspaceId,
    assistantId,
  });
  const { aiLabel } = messagingSettings;
  const [inputField] = useState({
    aiLabel: aiLabel || "",
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = (data) => {
    const payload = {
      aiLabel: data.aiLabel,
    };

    updateMessagingSettings(payload);
  };

  return (
    <div className="w-2/4 border rounded-lg flex mx-auto p-8 mt-8">
      <Form
        formTitle="Label Settings"
        inputField={inputField}
        template={template}
        schema={schema}
        buttonInfo={buttonInfo}
        onSubmit={handleSubmit}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
}

export { AILabel };
