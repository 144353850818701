import { useEffect, useState } from "react";

function useMandatoryFields(extraction) {
  const [mandatoryFields, setMandatoryFields] = useState({
    fields: [],
    condition: "OR",
  });

  useEffect(() => {
    if (extraction) {
      setMandatoryFields(extraction.mandatoryFields);
    }
  }, [extraction]);

  const addToMandatoryField = (field) =>
    setMandatoryFields((prevState) => {
      return {
        ...prevState,
        fields: [...prevState.fields, field],
      };
    });

  const removeFromMandatoryField = (field) => {
    const nextFields = mandatoryFields.fields.filter(
      (_field) => _field != field
    );

    setMandatoryFields((prevState) => {
      return {
        ...prevState,
        fields: nextFields,
      };
    });
  };

  const changeMandatoryCondition = (condition) => {
    setMandatoryFields((prevState) => {
      return {
        ...prevState,
        condition,
      };
    });
  };

  const isMandatoryField = (field) => {
    const index = mandatoryFields.fields.findIndex((_field) => {
      return _field === field;
    });

    return index != -1;
  };

  return {
    mandatoryFields,
    isMandatoryField,
    addToMandatoryField,
    removeFromMandatoryField,
    changeMandatoryCondition,
  };
}

export { useMandatoryFields };
