import { useEffect, useState } from "react";
import { useWhatsappConfiguration } from "V2.0/HomePages/Integrations/Whatsapp/models/useWhatsappConfiguration";
import { useCommunicationBinding } from "./useCommunicationBinding";
import { PhoneNumberBinding } from "./PhoneNumberBinding/PhoneNumberBinding";

function WhatsAppCommunicationBinding(props) {
  const {
    communicationBinding,
    syncAssistant,
    workspaceId,
    assistantId,
    assistantType,
  } = props;
  const { addCommunicationBinding, removeCommunicationBinding } =
    useCommunicationBinding({ syncAssistant, assistantId, workspaceId });
  const [openDelete, setOpenDelete] = useState({
    item: null,
    isOpen: false,
  });
  const { whatsappConfiguration, fetchWhatsappConfiguration } =
    useWhatsappConfiguration(workspaceId);

  useEffect(() => {
    if (workspaceId) {
      fetchWhatsappConfiguration(workspaceId);
    }
  }, [workspaceId]);

  const handleBindingDelete = () => {
    removeCommunicationBinding(openDelete.item._id).then(() => {
      setOpenDelete({ item: null, isOpen: false });
    });
  };

  const handleBindingAdd = (data, closeDialog) => {
    const payload = {
      channelType: assistantType,
      isActive: true,
      ...data,
    };
    addCommunicationBinding(payload).then(closeDialog);
  };

  const phoneNumbers = whatsappConfiguration.data?.phoneNumberIds || [];

  return (
    <PhoneNumberBinding
      isOpen={openDelete.isOpen}
      onClose={() => setOpenDelete({ isOpen: false, item: null })}
      onBindingDelete={(item) => setOpenDelete({ isOpen: true, item })}
      onBindingAdd={handleBindingAdd}
      onBindingConfirmDelete={handleBindingDelete}
      phoneNumbers={phoneNumbers}
      communicationBinding={communicationBinding}
    />
  );
}

export { WhatsAppCommunicationBinding };
