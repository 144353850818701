import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "V2.0/common/dropdown";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "V2.0/common/table";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import * as knowledgeService from "V2.0/services/knowledgebaseService";
import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
} from "V2.0/common/alert";
import { Button } from "V2.0/common/button";
import { message } from "antd";
import Empty from "V2.0/images/empty.svg";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Dialog, DialogTitle, DialogBody } from "V2.0/common/dialog";
import UploadFileForEmbedding from "../HomeKBComponent/UploadFileForEmbedding";
import { DialogTitleWithClose } from "../HomeModule/CustomAPIs/components/Dialog/DialogTitleWithClose";
import { deleteCorpus, getDocumentDownloadLink } from "./service";

function AddFiles(props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button outline onClick={() => setOpen(true)}>
        <PlusIcon /> Add Files
      </Button>
      <Dialog size="2xl" open={open} onClose={() => {}}>
        <DialogTitle>
          <DialogTitleWithClose
            title="Add Files"
            onClose={() => setOpen(false)}
          />
        </DialogTitle>
        <DialogBody>
          <UploadFileForEmbedding {...props} onClose={() => setOpen(false)} />
        </DialogBody>
      </Dialog>
    </>
  );
}

function Files({ KB, projects, activeProject, reload, setReload, refetch }) {
  let [isOpen, setIsOpen] = useState(false);
  const [eTag, setEtag] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await deleteCorpus(
        KB._id,
        projects[activeProject]._id,
        eTag
      );

      if (response.status === 200) {
        refetch(projects[activeProject]._id, KB._id);
        setIsOpen(false);
        setLoading(false);
        setReload(!reload);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
        setIsOpen(false);
        setLoading(false);
        return;
      }
    }
  };

  const showDeleteModal = (etag) => {
    setIsOpen(true);
    setEtag(etag);
  };

  const Skeleton = () => {
    return (
      <div className="animate-pulse">
        <div className="h-4 bg-gray-200 rounded"></div>
      </div>
    );
  };

  const handletDocumentDownloadLink = async (etag, item) => {
    // if(item.type != "s3")
    // message.error("Only documents can be downloaded.");

    message.info("Please wait while we download the file...");

    try {
      const response = await getDocumentDownloadLink(
        projects[activeProject]._id,
        KB._id,
        etag
      );
      if (response.status === 200) {
        const link = document.createElement("a");
        link.href = response.data;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data);
        return;
      }
    }
  };

  return (
    <div className="flex flex-col max-h-[85dvh] p-8 gap-y-4">
      <div className="flex justify-between pb-4 border-b">
        <p className="text-xl font-semibold">Files</p>
        <AddFiles projects={projects} activeProject={activeProject} refetch={refetch} KB={KB}/>
      </div>
      <div className="border rounded-lg h-full p-4">
        <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] max-h-[80vh] overflow-scroll">
          <TableHead>
            <TableRow>
              <TableHeader>Data Type</TableHeader>
              <TableHeader>Content</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Actions</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {KB && KB.corpus ? (
              KB.corpus.filter((file) => file.type === "s3").length > 0 ? (
                KB.corpus
                  .filter((file) => file.type === "s3")
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="font-medium">{item.type}</TableCell>
                      <TableCell
                        className="truncate"
                        style={{ maxWidth: "200px" }}
                      >
                        {item.filename}
                      </TableCell>
                      <TableCell className="text-zinc-500">
                        {item.state}
                      </TableCell>
                      <TableCell>
                        <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                          <Dropdown>
                            <DropdownButton plain aria-label="More options">
                              <EllipsisHorizontalIcon />
                            </DropdownButton>
                            <DropdownMenu anchor="bottom end">
                              <DropdownItem
                                onClick={() => showDeleteModal(item.eTag)}
                              >
                                Delete
                              </DropdownItem>

                              <DropdownItem
                                onClick={() =>
                                  handletDocumentDownloadLink(
                                    item.eTag,
                                    item.type
                                  )
                                }
                              >
                                Download
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} className="text-center">
                    <div className="flex flex-col items-center justify-center m-5">
                      <img src={Empty} alt="No files" className="w-16 h-16" />
                      <p>You might not have uploaded any files yet.</p>
                    </div>
                  </TableCell>
                </TableRow>
              )
            ) : (
              [...Array(3)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      <Alert open={isOpen} onClose={setIsOpen}>
        <AlertTitle>Delete File</AlertTitle>
        <AlertDescription>
          By deleting this file, your project will no longer have access to this
          knowledge.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>
            {loading ? "Please wait..." : "Delete"}
          </Button>
        </AlertActions>
      </Alert>
    </div>
  );
}

export default Files;
