import { useState } from "react";
import { CustomAPIs } from "./CustomAPIs/CustomAPIs";
import { Extraction } from "./Extraction/Extraction";
import { TransferAgents } from "../TransferSettings/TransferAgents";

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { SMSTriggers } from "../CustomActions/SMSTriggers/SMSTriggers";
import { EndCallTriggers } from "../CustomActions/EndCallTriggers/EndCallTriggers";
import { StopCallRecordingTriggers } from "../CustomActions/StopCallRecordingTriggers/StopCallRecordingTriggers";
import { RealTimeBookings } from "./RealTimeBookings";
import { Webhooks } from "./Webhooks/Webhooks";

enum TOOL {
  WEBHOOKS = "Webhooks",
  EXTRACTION = "Extraction",
  CUSTOM_API = "Custom API",
  TRANSFER_AGENTS = "Transfer Agents",
  CALENDAR_APPOINTMENTS = "Calendar Appointments",
  TRIGGER_SMS = "Trigger SMS",
  END_CALL = "End Call",
  STOP_CALL_RECORDING = "Stop Call Recording"
}

const ToolComponent = {
  [TOOL.CUSTOM_API]: (props) => <CustomAPIs {...props} />,
  [TOOL.EXTRACTION]: (props) => <Extraction {...props} />,
  [TOOL.WEBHOOKS]: (props) => <Webhooks {...props} />,
  [TOOL.TRANSFER_AGENTS]: (props) => <TransferAgents {...props} />,
  [TOOL.CALENDAR_APPOINTMENTS]: (props) => <RealTimeBookings {...props} />,
  [TOOL.TRIGGER_SMS]: (props) => <SMSTriggers {...props} />,
  [TOOL.END_CALL]: (props) => <EndCallTriggers {...props} />,
  [TOOL.STOP_CALL_RECORDING]: (props) => <StopCallRecordingTriggers {...props} />
};

function SectionItem(props) {
  const { sectionName, onSectionClick, isActive, icon } = props;
  return (
    <div
      onClick={onSectionClick}
      className={`w-full h-12 flex items-center justify-between px-4 hover:bg-gray-300 hover:cursor-pointer ${
        isActive ? "bg-gray-200" : ""
      }`}
    >
      <div className="flex items-center gap-x-2">
        {icon && icon}
        <p className="font-medium text-sm">{sectionName}</p>
      </div>
    </div>
  );
}

function VoiceAssistantTools(props) {
  const [activeTool, setActiveTool] = useState(TOOL.WEBHOOKS);

  const tools = Object.values(TOOL).reduce((acc, tool) => {
    return [...acc, { name: tool, href: "#", current: tool === activeTool }];
  }, []);

  return (
    <div className="flex h-[calc(100vh-210px)]">
      <div className="w-1/5 border-r h-full pt-4">
        {tools.map((tool) => (
          <SectionItem
            sectionName={tool.name}
            isActive={tool.current}
            onSectionClick={() => setActiveTool(tool.name)}
          />
        ))}
      </div>
      <div className="w-4/5 px-4">
        {ToolComponent[activeTool]({
            ...props
        })}
      </div>
    </div>
  );
}

export { VoiceAssistantTools };
