import http from "./httpService";
const apiEndpoint = "/voice";

function placeVoiceCall({ workspaceId, assistantId, payload }) {
  return http.post(
    `${apiEndpoint}/whitelabelPlaceOutboundCall/${workspaceId}/${assistantId}`,
    payload
  );
}

export function placeCall(projectId, req) {
  return http.post(
    apiEndpoint + "/" + "whitelabelPlaceOutboundCall" + "/" + projectId,
    req
  );
}

export function registerTwilioNumber(projectId, req) {
  return http.post(
    apiEndpoint + "/" + "whitelabelRegisterNumber" + "/" + projectId,
    req
  );
}

export { placeVoiceCall };
