import {
  Dialog,
  DialogTitle,
  DialogDescription,
  DialogBody,
  DialogActions,
} from "V2.0/common/dialog";
import { Button } from "V2.0/common/button";

function ExtractionFieldDelete({ isOpen, onClose, onDelete, loading }) {
  return (
    <Dialog open={isOpen} onClose={onClose} size="sm">
      <DialogTitle>Delete Field</DialogTitle>
      <DialogDescription>
        Once deleted, this field will no longer be extracted from the
        conversation
      </DialogDescription>
      <DialogBody style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        ></div>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onDelete} disabled={loading}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { ExtractionFieldDelete };
