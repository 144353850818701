import { useState } from "react";

import Form from "../../../components/Form";
import Joi from "joi-browser";
import { message } from "antd";
import {
  addContactAttributesIfPresent,
  fieldSchema,
  fieldTemplates,
  fieldValues,
} from "./common/contactFields";

export function Sms(props) {
  const { addUserToCampaign, onClose, assistantId, fromNumber } = props;
  const [loading, setLoading] = useState(false);

  const inputField = {
    ...fieldValues,
    toNumber: "",
    fromNumber,
    triggerType: "",
    message: "",
    campaignType: "SMS",
    assistantId
  };

  const [errorMessage] = useState({});

  const template = [
    ...fieldTemplates,
    {
      name: "toNumber",
      label: "To Number",
      description: "Please pre-fix country code to the number",
      type: "text",
    },
    {
      name: "triggerType",
      label: "Trigger type",
      type: "select",
      options: [
        {
          id: 0,
          value: "",
          label: "No selection",
        },
        {
          id: 1,
          value: "triggerImmediately",
          label: "Trigger Immediately",
        },
        {
          id: 2,
          value: "triggerBasedOnCampaign",
          label: "Trigger based on campaign configuration",
        },
      ],
    },
    {
      name: "message",
      label: "Message",
      description: "The message to be sent to your contact",
      type: "textArea",
      style: { height: "100px" },
    },
  ];

  const schema = {
    ...fieldSchema,
    message: Joi.string().required().max(160).label("message"),
    toNumber: Joi.string()
      .regex(/^\+?[0-9]+$/)
      .required()
      .label("To Number"),
    fromNumber: Joi.string()
      .regex(/^\+?[0-9]+$/)
      .required()
      .label("From Number"),
    triggerType: Joi.string().required().label("Trigger Type"),
    campaignType: Joi.string().required().label("Campaign Type"),
    assistantId: Joi.string().required()
  };

  const handleSubmit = (data) => {
    const { firstName, lastName, email, ...rest } = data;
    const payload = {
      ...rest,
      ...addContactAttributesIfPresent(data)
    };
    addUserToCampaign(
      payload,
      (response) => {
        if (response.status === 200) {
          message.success("Success");
          onClose();
          setLoading(false);
        }
      },
      (error) => {
        message.error(error?.response?.data?.msg || "Failed to add contact");
        setLoading(false);
        onClose();
        console.error(error);
      }
    );
  };

  return (
    <Form
      key={JSON.stringify(inputField)}
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={{
        style: "",
        buttons: "2",
        primaryLabel: "Add",
        secondaryLabel: "Close",
      }}
      onSubmit={handleSubmit}
      loading={loading}
      setLoading={setLoading}
      handleSecondary={onClose}
    />
  );
}
