import { useState, useEffect } from "react";
import {
  createKnowledgebase,
  deleteKnowledgebase,
  getKnowledgebases,
} from "./service";
import { useOutletContext, useNavigate } from "react-router-dom";
import { KnowledgebaseTable } from "./KnowledgebaseTable";
import { KnowledgebaseCreate } from "./KnowledgebaseCreate";
import { toast } from "react-toastify";

import { knowledgeBasesMock } from "./mock/mock";

function useKnowledgeBases(projectId) {
  const [knowledgeBases, setKnowledgeBases] = useState([]);

  const fetchKnowledgeBases = async () => {
    try {
      const response = await getKnowledgebases({ projectId });
      if (response.status === 200) {
        setKnowledgeBases(response.data.knowledgebases);
      }
    } catch (error) {
      console.error("Error getting knowledge bases");
    }
  };

  return {
    knowledgeBases,
    fetchKnowledgeBases,
  };
}

export function KnowledgeBases() {
  const { projectId } = useOutletContext("");

  const { knowledgeBases, fetchKnowledgeBases } = useKnowledgeBases(projectId);

  const navigate = useNavigate();

  useEffect(() => {
    if (projectId) {
      fetchKnowledgeBases();
    }
  }, [projectId]);

  const handleCreateKnowledgebase = async (payload) => {
    try {
      const response = await createKnowledgebase({ projectId, payload });
      if (response.status === 201) {
        const { _id } = response.data;
        let kbId = _id;
        if (kbId) {
          navigate(`/knowledgebases/${kbId}`, {
            state: { knowledgebase: { _id: kbId } },
          });
        }
      }
    } catch (e) {
      console.error("Error creating knowledgebase");
    }
  };

  const handleKnowledgeBaseDelete = async (_id) => {
    try {
      const response = await deleteKnowledgebase({ projectId, _id });
      if (response.status === 200) {
        toast("Knowledge Bases deleted Successfully");
        await fetchKnowledgeBases();
      }
    } catch (e) {
      console.error("Error creating knowledgebase");
    }
  };

  return (
    <div className="flex h-full">
      <div className="w-full h-full border-r">
        <div className="flex justify-between p-4 items-center border-b">
          <p>Knowledge Base</p>
          <KnowledgebaseCreate
            projectId={projectId}
            onCreateKnowledgebase={handleCreateKnowledgebase}
          />
        </div>
        <div>
          <KnowledgebaseTable
            knowledgeBases={knowledgeBases}
            onDelete={handleKnowledgeBaseDelete}
          />
        </div>
      </div>
    </div>
  );
}
