import React, { useEffect, useState } from "react";
import { LinkIcon, PencilSquareIcon } from "@heroicons/react/20/solid";
import {
  useLocation,
  useOutletContext,
  useParams,
  useNavigate,
} from "react-router-dom";
import { getKnowledge } from "./models/service";
import {
  ChevronRightIcon,
  DocumentTextIcon,
  TableCellsIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";

import Paragraphs from "./Paragraphs";
import WebLinks from "./WebLinks";
import Files from "./Files";
import Spreadsheets from "./Spreadsheets";
import { getKnowledgebase } from "./service";
import { Button } from "V2.0/common/button";
import { knowledgeBaseMock, knowledgeBaseMock1 } from "./mock/mock";

const KNOWLEDGE_BASE = {
  PARAGRAPHS: "Paragraphs",
  WEB_LINKS: "Web Links",
  FILES: "Files",
  SPREADSHEET: "Spreadsheets",
};

const knowledgeBaseComponents = {
  [KNOWLEDGE_BASE.PARAGRAPHS]: (props) => <Paragraphs {...props} />,
  [KNOWLEDGE_BASE.WEB_LINKS]: (props) => <WebLinks {...props} />,
  [KNOWLEDGE_BASE.FILES]: (props) => <Files {...props} />,
  [KNOWLEDGE_BASE.SPREADSHEET]: (props) => <Spreadsheets {...props} />,
};

const KnowledgeBaseIcons = {
  [KNOWLEDGE_BASE.PARAGRAPHS]: (
    <PencilSquareIcon className="size-5 text-gray-500" />
  ),
  [KNOWLEDGE_BASE.WEB_LINKS]: <LinkIcon className="size-5 text-gray-500" />,
  [KNOWLEDGE_BASE.FILES]: <DocumentTextIcon className="size-5 text-gray-500" />,
  [KNOWLEDGE_BASE.SPREADSHEET]: (
    <TableCellsIcon className="size-5 text-gray-500" />
  ),
};

function KnowledgeBaseSection(props) {
  const { sectionName, onSectionClick, isActive, icon } = props;
  return (
    <div
      onClick={onSectionClick}
      className={`w-full h-16 flex items-center justify-between px-4 hover:bg-gray-300 hover:cursor-pointer ${
        isActive ? "bg-gray-200" : ""
      }`}
    >
      <div className="flex items-center gap-x-2">
        {icon && icon}
        <p className="font-semibold text-sm">{sectionName}</p>
      </div>
      <div className="flex justify-end">
        <ChevronRightIcon className="size-5 text-gray-400" />
      </div>
    </div>
  );
}

// function useKnowledgeBase(projectId) {
//   const [knowledgeBase, setKnowledgeBase] = useState({
//     data: {
//       paragraphs: [],
//       corpus: [],
//     },
//     loading: true,
//     exists: false,
//   });
//   const { knowledgebaseId } = useParams();

//   const location = useLocation();
//   const state = location.state;

//   const fetchKnowledgeBase = async () => {
//     try {
//       const response = getKnowledgebase({ projectId, knowledgebaseId });
//       if (response.status === 200) {
//         const data = response?.data.;
//         setKnowledgeBase({ data, loading: false, exists: true });
//       }
//     } catch (error) {
//       console.error("Error getting knowledge base");
//     }
//   };

//   useEffect(() => {
//     if (projectId) {
//         fetchKnowledgeBase();
//     }
//   }, [projectId, knowledgebaseId]);

//   return { knowledgeBase, fetchKnowledgeBase };
// }

export function KnowledgeBase({}) {
  const [activeTab, setActiveTab] = useState(KNOWLEDGE_BASE.PARAGRAPHS);
  const [knowledge, setKnowledge] = useState({
    data: {
      paragraphs: [],
      corpus: [],
    },
    loading: true,
    exists: false,
  });
  const { projects, activeProject, projectId } = useOutletContext();
  // const { knowledge, fetchKnowledgeBase } = useKnowledgeBase(projectId);
  const [dialogMode, setDialogMode] = useState({ isOpen: false, mode: null });
  const navigate = useNavigate();

  const tabs = Object.values(KNOWLEDGE_BASE).map((tab) => ({
    name: tab,
    current: tab === activeTab,
    href: "#",
  }));

  const { knowledgebaseId } = useParams();

  const fetchKnowledge = async (projectId, knowledgebaseId) => {
    try {
      const response = await getKnowledgebase({ projectId, knowledgebaseId });
      if (response.data) {
        const data = response.data.knowledgeBase;
        console.log({ data });

        setKnowledge({ data, loading: false, exists: true });
      } else {
        setKnowledge((prev) => ({ ...prev, loading: false, exists: false }));
      }
    } catch (ex) {
      setKnowledge((prev) => ({ ...prev, loading: false }));
      console.error(ex);
    }
  };

  useEffect(() => {
    if (projectId && knowledgebaseId) {
      fetchKnowledge(projectId, knowledgebaseId);
    
    }
  }, [projectId, knowledgebaseId]);

  return (
    <div className="flex w-full h-full">
      <div className="w-1/4 h-[calc(100vh-1rem)] border-r flex flex-col">
        <div className="flex justify-between p-4 items-center border-b">
          <div className="flex gap-x-2">
            <Button outline onClick={() => navigate("/knowledgebases")}>
              <ChevronLeftIcon />
            </Button>
            <div>
              <p className="font-semibold text-md">
                {knowledge.data.label || "Knowledge Base"}
              </p>
              <p className="text-xs text-gray-500">ID: &nbsp;{knowledgebaseId} </p>
            </div>
          </div>
        </div>
        {tabs.map((tab) => (
          <KnowledgeBaseSection
            sectionName={tab.name}
            isActive={tab.current}
            onSectionClick={() => setActiveTab(tab.name)}
            icon={KnowledgeBaseIcons[tab.name]}
          />
        ))}
      </div>
      <div className="w-3/4 h-100vh">
        {knowledgeBaseComponents[activeTab]({
          originalKBData: knowledge?.data?.paragraphs || [],
          KB: knowledge.data,
          projectId: projectId,
          projects,
          activeProject,
          setEditItem: () => {},
          kbLoading: knowledge?.loading,
          kbExists: knowledge?.exists,
          refetch: () => fetchKnowledge(projectId, knowledgebaseId),
        })}
      </div>
    </div>
  );
}
