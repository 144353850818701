import React, { useState } from "react";

import { Button } from "V2.0/common/button";

import Form from "../components/Form";
import { useForm } from "../components/Form/useForm";
import { IndividualAPITemplate } from "./models/template";

import { ReturnParam } from "../Params/Return";
import { InputParams } from "../Params/Input";
import { individualAPISchema } from "./models/schema";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";

export function IndividualAPI({ item, goBack, onConfirm }) {
  const [individualAPI, setIndividualAPI] = useState(item);

  const [inputField] = useState({
    apiName: individualAPI.apiName,
    apiMethod: individualAPI.apiMethod,
    apiTriggerPrompt: individualAPI.apiTriggerPrompt,
    triggerEvent: individualAPI.triggerEvent,
    callOnce: individualAPI.callOnce,
    apiURL: individualAPI.apiURL,
    backchannelMessage: individualAPI.backchannelMessage,
    successResponse: individualAPI.successResponse,
    failureResponse: individualAPI.failureResponse,
  });

  const { data, error, handleChange, validate, setError } = useForm(
    inputField,
    individualAPISchema
  );

  function handleSubmit() {
    const errors = validate(data, individualAPISchema);

    if (errors) {
      setError(errors);
      return;
    }

    onConfirm({
      ...data,
      inputParams: individualAPI?.inputParams || [],
      returnParams: individualAPI?.returnParams || [],
    });
    goBack();
  }

  function addInputParam(param) {
    setIndividualAPI((prevState) => ({
      ...prevState,
      inputParams: [...prevState.inputParams, param],
    }));
  }

  function addReturnParam(param) {
    setIndividualAPI((prevState) => ({
      ...prevState,
      returnParams: [...prevState.returnParams, param],
    }));
  }

  function updateInputParam(param, index) {
    const { _id } = param;

    setIndividualAPI((prevState) => ({
      ...prevState,
      inputParams: prevState.inputParams.toSpliced(index, 1, param),
    }));
  }

  function updateReturnParam(param) {
    const { _id } = param;
    const index = individualAPI.returnParams.findIndex(
      (item) => item._id === _id
    );
    setIndividualAPI((prevState) => ({
      ...prevState,
      returnParams: prevState.returnParams.toSpliced(index, 1, param),
    }));
  }

  function deleteInputParam(param, index) {
    setIndividualAPI((prevState) => ({
      ...prevState,
      inputParams: prevState.inputParams.toSpliced(index, 1),
    }));
  }

  function deleteReturnParam(param, index) {
    setIndividualAPI((prevState) => ({
      ...prevState,
      returnParams: prevState.returnParams.toSpliced(index, 1),
    }));
  }

  return (
    <div className="flex flex-col basis:full gap-8 max-h-[calc(100vh-350px)]">
      <Button className="self-start" plain onClick={goBack}>
        <ArrowLeftIcon color="#jfa8asj" />
        <span className="text-blue-600">Go back</span>
      </Button>
      <Form
        key={JSON.stringify(inputField)}
        data={data}
        handleChange={handleChange}
        error={error}
        template={IndividualAPITemplate}
      />
      <InputParams
        inputParams={individualAPI.inputParams}
        addInputParam={addInputParam}
        updateInputParam={updateInputParam}
        deleteInputParam={deleteInputParam}
      />
      <ReturnParam
        returnParams={individualAPI.returnParams}
        addReturnParam={addReturnParam}
        updateReturnParam={updateReturnParam}
        deleteReturnParam={deleteReturnParam}
      />
      <div className="py-8 flex justify-end gap-x-4">
        <Button outline onClick={goBack}>
          Cancel
        </Button>
        <Button disabled={Object.keys(error).length > 0} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
}
