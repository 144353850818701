import { useMemo, useState } from "react";
import { TVoiceAssistant, voiceAssistant } from "../mock/voiceAssistant";
import TabsWithBadge from "V2.0/common/tabsWithBadge";
import { GPTResponseModel, PromptSettings } from "../ModelSettings";
import { VoiceAssistantTools } from "../AssistantTools/VoiceAssistantTools";
import { Button } from "V2.0/common/button";
import { updateAssistant } from "../service";
import { VoiceAssistantSettings } from "../AssistantSettings/VoiceAssistantSettings";
import { Transcription } from "../TranscriptionSettings/Transcription";
import { TTS } from "../TTS/TTS";
import {
  Dropdown,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
} from "V2.0/common/dropdown";
import DemoComponent from "V2.0/components/DemoComponent";
import { useNavigate, useOutletContext } from "react-router-dom";
import { TestVoice } from "./AssistantTest/TestVoice";
import {
  ChevronLeftIcon,
  Cog8ToothIcon,
  CogIcon,
  DevicePhoneMobileIcon,
  DocumentArrowDownIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  TrashIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { VoiceCommunicationBinding } from "../CommunicationBinding/VoiceCommunicationBinding";
import { KnowledgeBase } from "../RAG/KnowledgeBase/KnowledgeBase";

enum TABS {
  PROMPT = "Prompt",
  TOOLS = "Tools",
  SETTINGS = "Settings",
  PHONE_NUMBERS = "Phone Numbers",
  KNOWLEDGE = "Knowledge base",
}

const tabsMap = {
  [TABS.PROMPT]: (props) => <PromptSettings {...props} />,
  [TABS.KNOWLEDGE]: (props) => <KnowledgeBase {...props} />,
  [TABS.SETTINGS]: (props) => <VoiceAssistantSettings {...props} />,
  [TABS.TOOLS]: (props) => <VoiceAssistantTools {...props} />,
  [TABS.PHONE_NUMBERS]: (props) => <VoiceCommunicationBinding {...props} />,
};

const TAB_ICON = {
  [TABS.PROMPT]: DocumentDuplicateIcon,
  [TABS.KNOWLEDGE]: FolderIcon,
  [TABS.SETTINGS]: Cog8ToothIcon,
  [TABS.TOOLS]: WrenchIcon,
  [TABS.PHONE_NUMBERS]: DevicePhoneMobileIcon,
};

function VoiceAssistant(props) {
  const {
    workspaceId,
    name,
    assistantId,
    ttsSettings,
    transcriptionSettings,
    modelSettings,
    syncAssistant,
    communicationBinding,
  } = props;
  const { projects, activeProject, projectId } = useOutletContext<{
    projects: [];
    activeProject: object;
    projectId: string;
  }>();
  const [activeTab, setActiveTab] = useState<TABS>(TABS.PROMPT);
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const [callOpen, setCallOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const tabs = Object.values(TABS).reduce((acc, tab) => {
    const Icon = TAB_ICON[tab];
    return [
      ...acc,
      {
        name: tab,
        href: "#",
        current: tab === activeTab,
        icon: <Icon className="size-5" />,
      },
    ];
  }, []);

  const fromNumbers = useMemo(() => {
    return communicationBinding.map((binding) => {
      return binding.identifier;
    });
  }, [communicationBinding]);

  return (
    <>
      <div className="flex h-full">
        <div className="w-full h-full border-r">
          <div>
            <div className="flex justify-between p-4 items-center border-b">
              <div className="flex gap-x-2">
                <Button outline onClick={() => navigate("/assistants")}>
                  <ChevronLeftIcon />
                </Button>
                <div>
                  <p className="font-semibold text-md">{name}</p>
                  <p className="text-sm">ID: &nbsp;{assistantId} </p>
                </div>
              </div>
              <div>
                <Dropdown>
                  <DropdownButton>Test Assistant</DropdownButton>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        setCallOpen(true);
                      }}
                    >
                      Phone Call
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setChatOpen(true);
                      }}
                    >
                      Chat
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <div className="flex justify-between p-4 border-b">
              <div className="flex gap-x-2">
                <TTS
                  settings={ttsSettings}
                  syncAssistant={syncAssistant}
                  workspaceId={workspaceId}
                  assistantId={assistantId}
                />
                <Transcription
                  settings={transcriptionSettings}
                  syncAssistant={syncAssistant}
                  workspaceId={workspaceId}
                  assistantId={assistantId}
                />
              </div>
              <GPTResponseModel
                settings={modelSettings}
                syncAssistant={syncAssistant}
                workspaceId={workspaceId}
                assistantId={assistantId}
              />
            </div>
          </div>
          <div className="">
            <div className="border-b px-4">
              <TabsWithBadge
                tabs={tabs}
                className=""
                onTabChange={(e, { tab, index }) => setActiveTab(tab.name)}
              />
            </div>
            <div>
              {tabsMap[activeTab]({
                ...props,
                projectId,
              })}
            </div>
          </div>
        </div>
      </div>
      {chatOpen && (
        <DemoComponent
          open={chatOpen}
          setOpen={setChatOpen}
          projects={projects}
          activeProject={activeProject}
          assistantId={assistantId}
          SidebarTitle="Chat with Assistant"
        />
      )}
      {callOpen && (
        <TestVoice
          fromNumbers={fromNumbers}
          projectId={projectId}
          assistantId={assistantId}
          open={callOpen}
          onClose={() => setCallOpen(false)}
        />
      )}
    </>
  );
}

export { VoiceAssistant };
