import { useState, useEffect } from "react";
import { createAssistant, getAssistants, removeAssistant } from "./service";
import { useOutletContext, useNavigate } from "react-router-dom";
import { AssistantsTable } from "./AssistantsTable";
import { AssistantCreate } from "./AssistantCreate";
import { TVoiceAssistant } from "./mock/voiceAssistant";
import { TSMSAssistant } from "./mock/smsAssistant";
import { TWhatsappAssistant } from "./mock/whatsappAssistant";
import { TEmailAssistant } from "./mock/emailAssistant";
import { TEmbedAssistant } from "./mock/embedAssistant";
import {
  Dialog,
  DialogTitle,
  DialogBody,
  DialogActions,
} from "V2.0/common/dialog";
import { Button } from "V2.0/common/button";

type Assistant =
  | TVoiceAssistant
  | TSMSAssistant
  | TWhatsappAssistant
  | TEmailAssistant
  | TEmbedAssistant;

function useAssistants(projectId: string) {
  const [assistants, setAssistants] = useState<Assistant[]>([]);

  const fetchAssistants = async () => {
    try {
      const response = await getAssistants({ projectId });
      if (response.status === 200) {
        setAssistants(response.data.assistants);
      }
    } catch (error) {
      console.error("Error getting assistants");
    }
  };

  return {
    assistants,
    fetchAssistants,
  };
}

export function Assistants() {
  const { projectId } = useOutletContext<{ projectId: string }>();

  const { assistants, fetchAssistants } = useAssistants(projectId);

  const navigate = useNavigate();

  const [openDelete, setOpenDelete] = useState({
    item: null,
    isOpen: false,
  });

  const onClose = () => setOpenDelete({ item: null, isOpen: false });

  useEffect(() => {
    if (projectId) {
      fetchAssistants();
    }
  }, [projectId]);

  const handleCreateAssistant = async (payload) => {
    try {
      const response = await createAssistant({ projectId, payload });
      if (response.status === 201) {
        const { _id } = response.data;
        navigate(`/assistants/${_id}`, { state: { assistant: response.data } });
      }
    } catch (e) {
      console.error("Error creating assistant");
    }
  };

  const handleDeleteAssistant = async () => {
    try {
      const response = await removeAssistant({
        workspaceId: projectId,
        assistantId: openDelete.item._id,
      });
      if (response.status === 200) {
        fetchAssistants();
        onClose();
      }
    } catch (e) {
      console.error("Error deleting assistant");
    }
  };

  return (
    <>
      <div className="flex h-full">
        <div className="w-full h-full border-r">
          <div className="flex justify-between p-4 items-center border-b">
            <p>Assistants</p>
            <AssistantCreate
              projectId={projectId}
              onCreateAssistant={handleCreateAssistant}
            />
          </div>
          <div>
            <AssistantsTable
              assistants={assistants}
              onDelete={(item) => setOpenDelete({ item, isOpen: true })}
            />
          </div>
        </div>
      </div>
      {openDelete.isOpen && (
        <Dialog open={true} onClose={onClose}>
          <DialogTitle>Delete Assistant?</DialogTitle>
          <DialogActions>
            <Button outline onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleDeleteAssistant}>Delete</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export { useAssistants };
