import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "V2.0/components/Form";
import { message } from "antd";
import * as knowledgeService from "V2.0/services/knowledgebaseService";
import { Dialog, DialogTitle, DialogBody } from "V2.0/common/dialog";
import { DialogTitleWithClose } from "V2.0/HomePages/HomeModule/CustomAPIs/components/Dialog/DialogTitleWithClose";
import { updateParagraph } from "../../service";


export function EditParagraph(props) {
  const { isOpen, onClose, ...restProps } = props;
  return (
    <Dialog size="5xl" open={isOpen} onClose={() => {}}>
      <DialogTitle>
        <DialogTitleWithClose title="Edit Paragraph" onClose={onClose} />
      </DialogTitle>
      <DialogBody>
        <EditTextData {...restProps} onClose={onClose}/>
      </DialogBody>
    </Dialog>
  );
}

function EditTextData({ editItem, KB, projects, activeProject, refetch, onClose }) {
  const [inputField] = useState({
    content: editItem.value.data,
  });

  const [errorMessage, setErrorMessage] = useState({});

  const [loading, setLoading] = useState(false);

  // Dynamically generate the template based on inputFields
  const template = [
    {
      name: "content",
      label: "",
      type: "textArea",
      style: { height: "300px" },
    },
  ];

  // Joi schema for dynamic validation
  const schema = {
    content: Joi.string()
      .regex(/^\s*(\S+\s+){0,400}\S*$/)
      .options({
        language: {
          string: {
            regex: { base: "This section must contain no more than 400 words" },
          },
        },
      })
      .label("this section"),
  };

  const handleSubmit = async (data) => {
    let result;
    message.info("Updating paragraph", 1);

    try {
      let serviceToCall;

      serviceToCall = updateParagraph;
      result = {
        key: editItem.key,
        data: data.content
      };

      const res = await updateParagraph({
        projectId: projects[activeProject]._id,
        knowledgebaseId: KB._id,
        payload: result
      });
      if (res.status === 200) {
        message.success("Paragraph updated");
        refetch(projects[activeProject]._id, KB._id);
        onClose();
      }
    } catch (ex) {
      setLoading(false);
      if (ex.response && ex.response.status === 400) {
        message.error(ex.response.data);
      } else {
        // Handle other errors or invalid hostname error
        message.error(ex.message || "An unexpected error occurred.");
      }
      onClose();
    }
  };
  // const output = Object.values(data)
  //   .filter((value) => value !== "")
  //   .map((value) => ({ value: { data: value } }));

  // const finalData = {
  //   label: KB.label,
  //   projectId: projects[activeProject]._id,
  //   knowledgeBasePatchObject: {
  //     add: output,
  //   },
  // };

  // finalData.paragraphs = output;

  // try {
  //   const response = await knowledgeService.updateKB(finalData);
  //   if (response.status === 200) {
  //     setFormState("Existing Text Data");
  //     setReload(!reload);
  //   }
  // } catch (ex) {
  //   if (
  //     ex.response &&
  //     ex.response.status >= 400 &&
  //     ex.response.status < 500
  //   ) {
  //     setLoading(false);

  //     message.error(ex.response.data.msg);
  //     return;
  //   }
  // }

  return (
    <div className="px-2">
      <Form
        errorMessage={errorMessage}
        inputField={inputField}
        template={template}
        schema={schema}
        buttonInfo={{
          label: "Update",
          style: "right-side",
        }}
        onSubmit={handleSubmit}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
}

export default EditTextData;
