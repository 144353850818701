import { useState, useMemo, useEffect } from "react";

import {
  Dialog,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../../common/dialog";

import { Call } from "./SingleContact/Call";
import { Sms } from "./SingleContact/SMS";
import { Whatsapp } from "./SingleContact/Whatsapp";
import InputComponent from "V2.0/components/InputComponent";
import { useAssistants } from "V2.0/HomePages/Assistants";
import { useAssistantOptions } from "./useAssistantOptions";
import { useAssistantPhoneNumbers } from "./useAssistantPhoneNumbers";

export const CAMPAIGN_TYPE = {
  SMS: "SMS",
  CALL: "Call",
  WHATSAPP: "Whatsapp",
};

const ASSISTANT_CAMPAIGN_MAPPING = {
  [CAMPAIGN_TYPE.SMS]: "sms",
  [CAMPAIGN_TYPE.CALL]: "voice",
  [CAMPAIGN_TYPE.WHATSAPP]: "whatsapp",
};

const campaignType = {
  [CAMPAIGN_TYPE.SMS]: (props) => <Sms {...props} />,
  [CAMPAIGN_TYPE.CALL]: (props) => <Call {...props} />,
  [CAMPAIGN_TYPE.WHATSAPP]: (props) => <Whatsapp {...props} />,
};

export function SingleContact(props) {
  const { addUserToCampaign, dialogState, onClose, projectId } = props;
  const [activeTab, setActiveTab] = useState(CAMPAIGN_TYPE.SMS);
  const { assistants, fetchAssistants } = useAssistants(projectId);
  const { assistantOptions, assistantId, setAssistantId } = useAssistantOptions(
    assistants,
    activeTab
  );
  const { fromNumber, setFromNumber, phoneNumbers } = useAssistantPhoneNumbers(
    assistantId,
    projectId
  );

  useEffect(() => {
    if (projectId) {
      fetchAssistants();
    }
  }, [projectId]);

  return (
    <Dialog open={dialogState.isOpen} onClose={onClose} size="lg">
      <DialogTitle>Add Contact to Campaign</DialogTitle>
      <DialogBody>
        <InputComponent
          label="Campaign type"
          type="select"
          options={[
            { id: 1, label: CAMPAIGN_TYPE.SMS, value: CAMPAIGN_TYPE.SMS },
            { id: 2, label: CAMPAIGN_TYPE.CALL, value: CAMPAIGN_TYPE.CALL },
            {
              id: 2,
              label: CAMPAIGN_TYPE.WHATSAPP,
              value: CAMPAIGN_TYPE.WHATSAPP,
            },
          ]}
          value={activeTab}
          onChange={(e) => setActiveTab(e.target.value)}
        />
        <InputComponent
          label="Assistant"
          type="select"
          options={assistantOptions}
          value={assistantId}
          onChange={(e) => setAssistantId(e.target.value)}
        />
        <InputComponent
          label="From Number"
          type="select"
          options={phoneNumbers}
          value={fromNumber}
          onChange={(e) => setFromNumber(e.target.value)}
        />

        {campaignType[activeTab]({
          addUserToCampaign,
          onClose,
          projectId,
          assistantId,
          fromNumber
        })}
      </DialogBody>
    </Dialog>
  );
}
