import { useState } from "react";
import Form from "V2.0/components/Form";
import Joi from "joi-browser";

const template = [
  { name: "webhookName", label: "Name", type: "text" },
  {
    name: "eventName",
    label: "Event Type",
    type: "select",
    options: [
      { id: 0, value: "", label: "No selection" },
      {
        id: 1,
        value: "captureEvent",
        label: "Capture details from conversation",
      },
    ],
  },

  {
    name: "endpointUrl",
    label: "Endpoint URL",
    type: "text",
  },

  {
    name: "authRequired",
    label: "Auth required",
    description: "Only enable it if Auth is required",
    type: "toggle",
  },

  {
    name: "authKey",
    label: "Auth Key",
    type: "text",
  },
];

const buttonInfo = {
  style: "",
  buttons: "2",
  primaryLabel: "Create A Webhook",
  secondaryLabel: "Close",
};

const schema = {
  webhookName: Joi.string().allow(null, ""),
  eventName: Joi.string().required().label("event name"),
  endpointUrl: Joi.string().required().label("endpointUrl"),
  authRequired: Joi.boolean().label("Auth required"),
  authKey: Joi.string().allow(null, "").label("authKey"),
};

export function WebhookForm({ handleClose, onSubmit }) {
  const [inputField] = useState({
    eventName: "",
    endpointUrl: "",
    authKey: "",
    authRequired: false,
    webhookName: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  return (
    <Form
      errorMessage={errorMessage}
      inputField={inputField}
      template={template}
      schema={schema}
      buttonInfo={buttonInfo}
      onSubmit={onSubmit}
      loading={loading}
      setLoading={setLoading}
      handleSecondary={handleClose}
    />
  );
}
