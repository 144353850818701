import { useMemo } from "react";
import { TableComponent } from "V2.0/HomePages/HomeModule/CustomAPIs/components/TableComponent";

const getTime = (data) => {
  if (data === 0) return "Never";

  const epochTime = data;
  const date = new Date(epochTime);
  const dateString = date.toLocaleString(); // or use toUTCString() or toDateString() as needed
  return dateString;
};

const webhookTableHeaders = [
  {
    key: "webhookName",
    label: "Name",
  },
  {
    key: "endpointUrl",
    label: "URL",
  },
  {
    key: "authRequired",
    label: "Authenticated",
  },
  {
    key: "createdAt",
    label: "Created at",
  },
];

export function WebhooksTable({ webhooks, onWebhookDelete }) {
  const transformedWebhooks = useMemo(() => {
    return webhooks.map((webhook) => {
      return {
        ...webhook,
        createdAt: getTime(webhook.createdAt),
      };
    });
  }, [webhooks]);

  return (
    <TableComponent
      data={transformedWebhooks}
      headers={webhookTableHeaders}
      onRowClick={() => {}}
      onDelete={onWebhookDelete}
    />
  );
}
