import { useMemo, useState } from "react";
import {
  Table,
  TableHead,
  TableCell,
  TableHeader,
  TableBody,
  TableRow,
} from "V2.0/common/table";
import { Button } from "V2.0/common/button";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CustomActionDelete } from "../components/CustomActionDelete";
import { CustomActionToggle } from "../components/CustomActionToggle";
import { SMSTriggerEdit } from "./SMSTriggerEdit";
import { SMSTriggerCreate } from "./SMSTriggerCreate";
import { useCustomActions } from "../useCustomActions";

function SMSTriggers(props) {
  const { customActions, assistantId, workspaceId, syncAssistant } = props;
  const { customActionsArray, smsTriggersEnabled } = customActions;
  const {
    removeCustomAction,
    updateCustomAction,
    createCustomAction,
    toggleCustomAction,
  } = useCustomActions({ syncAssistant, workspaceId, assistantId });
  const [openEdit, setOpenEdit] = useState({
    isOpen: false,
    item: null,
    index: null,
  });
  const [openDelete, setOpenDelete] = useState({
    isOpen: false,
    item: null,
    index: null,
  });

  const smsTriggers = useMemo(() => {

    if (customActionsArray.length > 0) {
      return customActionsArray.filter(
        (action) => action.actionFunction === "SMS"
      );
    }

    return [];
  }, [customActionsArray]);

  const handleItemDelete = () => {
    removeCustomAction(openDelete.item._id).then(() => {
      setOpenDelete({ isOpen: false, item: null, index: -1 });
    });
  };

  const handleItemEdit = (data) => {
    updateCustomAction(data, openEdit.item._id).then(() =>
      setOpenEdit({ isOpen: false, item: null, index: -1 })
    );
  };

  const handleToggleCustomAction = (state) => {
    toggleCustomAction({ smsTriggersEnabled: state });
  };

  return (
    <>
      <div className="p-4 flex flex-col gap-y-4">
        <div className="flex justify-between">
          <div className="flex items-center gap-x-2">
            <p>SMS Triggers</p>
            <CustomActionToggle
              isEnabled={smsTriggersEnabled}
              onToggleCustomAction={handleToggleCustomAction}
            />
          </div>
          <SMSTriggerCreate onTriggerCreate={createCustomAction} />
        </div>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>Event name</TableHeader>
                <TableHeader>Phone Number</TableHeader>
                <TableHeader>Action</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {smsTriggers.length > 0 &&
                smsTriggers.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="font-medium">
                      {item.actionName}
                    </TableCell>
                    <TableCell>
                      {item.phoneNumber === ""
                        ? "Identified from call"
                        : item.phoneNumber}
                    </TableCell>

                    <TableCell>
                      <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                        <div className="-mx-3 -my-1.5 sm:-mx-2.5 flex flex-row gap-x-2">
                          <Button
                            outline
                            onClick={() =>
                              setOpenEdit({ isOpen: true, item, index })
                            }
                          >
                            <PencilIcon />
                          </Button>
                          <Button
                            outline
                            onClick={() =>
                              setOpenDelete({ isOpen: true, item, index })
                            }
                          >
                            <TrashIcon />
                          </Button>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </div>
      {openDelete.isOpen && (
        <CustomActionDelete
          open={true}
          onClose={() =>
            setOpenDelete({ isOpen: false, item: null, index: null })
          }
          onDelete={handleItemDelete}
        />
      )}
      {openEdit.isOpen && (
        <SMSTriggerEdit
          open={true}
          onClose={() =>
            setOpenEdit({ isOpen: false, item: null, index: null })
          }
          onTriggerEdit={handleItemEdit}
          smsTrigger={openEdit.item}
        />
      )}
    </>
  );
}

export { SMSTriggers };
