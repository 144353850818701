import { useState } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Button } from "V2.0/common/button";
import { Dialog, DialogTitle, DialogBody } from "V2.0/common/dialog";
import { PhoneNumberSelect } from "./PhoneNumberSelect";

function PhoneNumberAdd(props) {
  const { workspaceId, onPhoneNumberAdd, phoneNumbers } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button outline onClick={() => setOpen(true)}>
        <PlusIcon /> Add Phone Number
      </Button>
      {open && (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Add Phone Number</DialogTitle>
          <DialogBody>
            <PhoneNumberSelect
              workspaceId={workspaceId}
              onSubmit={(data) => onPhoneNumberAdd(data, () => setOpen(false))}
              onCancel={() => setOpen(false)}
              phoneNumbers={phoneNumbers}
            />
          </DialogBody>
        </Dialog>
      )}
    </>
  );
}

export { PhoneNumberAdd };
