import { useState } from "react";
import { Button } from "V2.0/common/button";
import { Dialog, DialogTitle, DialogBody } from "V2.0/common/dialog";
import Joi from "joi-browser";
import Form from "V2.0/components/Form";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { message } from "antd";
import { getTransferSettingsByAssistantType } from "./getTransferSettingByAssistant";

const buttonInfo = {
  buttons: "2",
  primaryLabel: "Save",
  secondaryLabel: "Cancel",
};

export function TransferSettings(props) {
  const { settings, updateSettings, assistantType } = props;

  const defaultSettings = {
    transferMessage: settings?.transferMessage || "",
    transferOnMediaMessage: settings?.transferOnMediaMessage || false,
    officeHours: settings?.officeHours || false,
    humanDelay: settings?.humanDelay || 0,
  };

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { template, schema, inputField } = getTransferSettingsByAssistantType(
    assistantType,
    defaultSettings
  );

  function onSave(data) {
    updateSettings(data)
      .then(() => {
        setLoading(false);
        setOpen(false);
        message.success("Saved");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Button outline onClick={() => setOpen(true)}>
        <Cog6ToothIcon /> Settings
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Configure Transfer Settings</DialogTitle>
        <DialogBody>
          <Form
            inputField={inputField}
            loading={loading}
            setLoading={setLoading}
            buttonInfo={buttonInfo}
            handleSecondary={() => setOpen(false)}
            onSubmit={onSave}
            template={template}
            schema={schema}
          />
        </DialogBody>
      </Dialog>
    </>
  );
}
