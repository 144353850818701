import { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "V2.0/components/Form";
import { putModelSettings } from "../../service";

const template = [
  {
    name: "gptResponseModel",
    label: "Model",
    type: "select",
    options: [
      { id: 1, value: "gpt-4o-mini", label: "GPT 4o mini" },
      { id: 2, value: "superdash-35-turbo", label: "GPT 3.5 Turbo" },
      { id: 3, value: "gemini-1.5-flash", label: "Gemini 1.5 Flash" },
      { id: 4, value: "groq/llama3-8b-8192", label: "LLaMA3 8b" },
      { id: 5, value: "groq/llama3-70b-8192", label: "LLaMA3 70b" },
      { id: 6, value: "groq/mixtral-8x7b-32768", label: "Mixtral 8x7b" },
    ],
  },

  {
    name: "temperature",
    label: "Temperature",
    type: "slider",
    min: 0,
    max: 1,
    step: 0.1,
  },

  {
    name: "maxOutputTokens",
    label: "Maximum Output token",
    type: "slider",
    min: 100,
    max: 1000,
  },
];

const buttonInfo = { label: "Save", style: "right-side" };

const schema = {
  gptResponseModel: Joi.string().required().label("Transcription Language"),
  maxOutputTokens: Joi.label("Maximum output tokens"),
  temperature: Joi.label("Temperature"),
};

function GPTResponseModelSettings(props) {
  const { modelSettings, workspaceId, assistantId, syncAssistant } = props;
  const { gptResponseModel, temperature, maxOutputTokens } = modelSettings;

  const [inputField, setInputField] = useState({
    gptResponseModel,
    temperature,
    maxOutputTokens,
  });

  useEffect(() => {
    if (modelSettings) {
      const { gptResponseModel, temperature, maxOutputTokens } = modelSettings;
      setInputField({
        gptResponseModel,
        temperature,
        maxOutputTokens,
      });
    }
  }, [modelSettings]);

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const handleCreate = async (data) => {
    try {
      const response = await putModelSettings({
        workspaceId,
        assistantId,
        payload: data,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            modelSettings: response.data,
          };
        });
        setLoading(false);
      }
    } catch (e) {
      console.error("Error updating model settings");
      setLoading(false);
    }
  };

  return (
    <div>
      <Form
        key={JSON.stringify(inputField)}
        errorMessage={errorMessage}
        inputField={inputField}
        template={template}
        schema={schema}
        onSubmit={handleCreate}
        loading={loading}
        setLoading={setLoading}
        buttonInfo={buttonInfo}
      />
    </div>
  );
}

export { GPTResponseModelSettings };
