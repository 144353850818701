import { Dialog, DialogBody, DialogTitle } from "V2.0/common/dialog";
import { ExtractionFieldForm } from "./ExtractionFieldForm";

function ExtractionFieldEdit(props) {
  const {isOpen, onClose, item, onSubmit } = props;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Edit Field</DialogTitle>
      <DialogBody>
        <ExtractionFieldForm
          item={item}
          onSubmit={onSubmit}
          handleSecondary={onClose}
        />
      </DialogBody>
    </Dialog>
  );
}

export { ExtractionFieldEdit };
