import { useState } from "react";

import { Button } from "V2.0/common/button";
import { Dialog, DialogTitle, DialogActions } from "V2.0/common/dialog";

function WebhookDelete({ webhook, open, onClose, onWebhookDelete }) {
  const [loading, setLoading] = useState(false);

  return (
    open && (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Delete {webhook.webhookName}?</DialogTitle>
        <DialogActions>
          <Button outline onClick={onClose}>
            Cancel
          </Button>
          <Button loading={loading} onClick={onWebhookDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}

export { WebhookDelete };
