import { useLocation } from "react-router-dom";

import {
  Sidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarBody,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from "V2.0/common/sidebarCatalyst";
import { WorkspaceSwitcher } from "V2.0/HomePages/Workspaces/Workspace_switcher";
import { UserMenu } from "./User_menu";

import {
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  ListBulletIcon,
  BoltIcon,
  IdentificationIcon,
  BanknotesIcon,
  WrenchScrewdriverIcon
} from "@heroicons/react/24/outline";

const initialNavigation = [
  { name: "Dashboard", href: "dashboard", icon: <HomeIcon />, current: false },
  {
    name: "Assistants",
    href: "assistants",
    icon: <WrenchScrewdriverIcon />,
    current: false,
  },
  {
    name: "Knowledge base",
    href: "knowledgebases",
    icon: <FolderIcon />,
    current: false,
  },
  { name: "Threads", href: "threads", icon: <UsersIcon />, current: false },
  {
    name: "Contacts",
    href: "contacts",
    icon: <IdentificationIcon />,
    current: false,
  },
  // {
  //   name: "Prompt",
  //   href: "prompt",
  //   icon: <DocumentDuplicateIcon />,
  //   current: false,
  // },
  // { name: "Modules", href: "modules", icon: <ChartPieIcon />, current: false },
  {
    name: "Campaigns",
    href: "campaign",
    icon: <ListBulletIcon />,
    current: false,
  },
  {
    name: "Integrations",
    href: "integrations",
    icon: <BoltIcon />,
    current: false,
  },
  {
    name: "API Settings",
    href: "settings",
    icon: <Cog6ToothIcon />,
    current: false,
  },
];

const navigationWithBilling = [
  ...initialNavigation,
  { name: "Billing", href: "billing", icon: <BanknotesIcon />, current: false },
];

export function SidebarNavigation(props) {
  const {
    projects,
    activeProject,
    setActiveProject,
    user,
    firstNameShort,
    setIsOpen,
    company,
    loading,
  } = props;

  const location = useLocation();

  const navigation =
    company && company.showBilling ? navigationWithBilling : initialNavigation;

  const navigationItems = navigation.map((nav) => ({
    ...nav,
    current: nav.href === location.pathname.split("/")[1],
  }));

  const hideLogo = localStorage.getItem("hideLogo") === "true";

  return (
    <Sidebar>
      {!hideLogo && (
        <SidebarHeader>
          <div className="flex items-center my-0">
            <img
              src={company.companyLogoURL}
              style={{ maxWidth: `${company.logoWidth}%` }}
            />
          </div>
        </SidebarHeader>
      )}
      <SidebarBody>
        <SidebarSection>
          <WorkspaceSwitcher
            projects={projects}
            activeProject={activeProject}
            setActiveProject={setActiveProject}
            setIsOpen={setIsOpen}
            loading={loading}
          />
          <SidebarSpacer />
        </SidebarSection>
        {navigationItems.map((item) => (
          <SidebarItem
            key={item.href}
            to={item.href}
            current={item.current}
            active={item.current}
          >
            {item.icon}
            <SidebarLabel>{item.name}</SidebarLabel>
          </SidebarItem>
        ))}
      </SidebarBody>
      <SidebarFooter>
        {/*<UserMenu initials={firstNameShort} user={user} /> /*TODO*/}
        <UserMenu initials={""} user={user} />
      </SidebarFooter>
    </Sidebar>
  );
}
