import { useState } from "react";

import {
  postCustomApisArray,
  putCustomApisArray,
  deleteCustomApisArray,
  putCustomApis,
} from "V2.0/HomePages/Assistants/service";

export function useCustomAPIs({
  workspaceId,
  assistantId,
  customAPIs,
  syncAssistant,
}) {
  const { customAPIsArray, customAPIsEnabled } = customAPIs;
  const [loading, setLoading] = useState(false);

  const updateCustomApis = async (payload) => {
    try {
      setLoading(true);
      const response = await putCustomApis({
        workspaceId,
        assistantId,
        payload,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            customAPIs: response.data,
          };
        });
      }
      setLoading(false);
    } catch (e) {
      console.error("Error updating custom apis");
      setLoading(false);
    }
  };

  const addCustomApisArray = async (payload) => {
    try {
      setLoading(true);
      const response = await postCustomApisArray({
        workspaceId,
        assistantId,
        payload,
      });
      if (response.status === 201) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            customAPIs: {
              ...prevAssistant.customAPIs,
              customAPIsArray: response.data
            },
          };
        });
      }
      setLoading(false);
    } catch (e) {
      console.error("Error adding custom api");
      setLoading(false);
    }
  };

  const updateCustomApisArray = async (customApiId, payload) => {
    try {
      setLoading(true);
      const response = await putCustomApisArray({
        workspaceId,
        assistantId,
        customApiId,
        payload,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            customAPIs: {
              ...prevAssistant.customAPIs,
              customAPIsArray: response.data
            },
          };
        });
      }
      setLoading(false);
    } catch (e) {
      console.error("Error updating custom api");
      setLoading(false);
    }
  };

  const removeCustomApisArray = async (customApiId) => {
    try {
      setLoading(true);
      const response = await deleteCustomApisArray({
        workspaceId,
        assistantId,
        customApiId,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            customAPIs: {
              ...prevAssistant.customAPIs,
              customAPIsArray: response.data
            },
          };
        });
      }
      setLoading(false);
    } catch (e) {
      console.error("Error adding custom api");
      setLoading(false);
    }
  };

  return {
    customAPIsArray,
    customAPIsEnabled,
    loading,
    updateCustomApis,
    addCustomApisArray,
    updateCustomApisArray,
    removeCustomApisArray,
  };
}
