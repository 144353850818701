import { useState, useEffect } from "react";
import { Switch } from "V2.0/common/switch";

function TransferAgentToggle({ isEnabled, onToggle }) {
  const [transferEnabled, setTransferEnabled] = useState(false);

  useEffect(() => {
    setTransferEnabled(isEnabled);
  }, [isEnabled]);

  const handleToggle = async () => {
    setTransferEnabled(!transferEnabled);
    onToggle(!transferEnabled);
  };

  return (
    <Switch
      color="emerald"
      id={`checkbox-${transferEnabled}`}
      checked={transferEnabled}
      onClick={handleToggle}
    />
  );
}

export { TransferAgentToggle };
