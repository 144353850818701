import { useState } from "react";
import { Button } from "V2.0/common/button";
import { CalendarSearchEnabled } from "./CalendarSearchEnabled";
import { DaysToCheckSlotsFor } from "./DaysToCheckSlotsFor";
import { putRealTimeBookings } from "../../service";

export function RealTimeBookings(props) {
  const { realTimeBooking, syncAssistant, workspaceId, assistantId } = props;
  const [realTimeBookings, setRealTimeBookings] = useState({
    calendarSearchEnabled: realTimeBooking.calendarSearchEnabled || false,
    daysToCheckSlotsFor: realTimeBooking.daysToCheckSlotsFor || "2",
  });

  const updateCalendarSearchEnabled = () => {
    setRealTimeBookings((prevState) => {
      return {
        ...prevState,
        calendarSearchEnabled: !prevState.calendarSearchEnabled,
      };
    });
  };

  const updateDaysToCheckSlotFor = (data) => {
    setRealTimeBookings((prevState) => {
      return {
        ...prevState,
        daysToCheckSlotsFor: data,
      };
    });
  };

  const handleSave = async () => {
    try {
      const response = await putRealTimeBookings({
        workspaceId,
        assistantId,
        payload: realTimeBookings,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            realTimeBooking: response.data,
          };
        });
      }
    } catch (e) {
      console.error("Error saving real time bookings");
    }
  };

  return (
    <div className="flex flex-col w-2/4 mx-auto gap-y-8 mt-8 border rounded-lg p-8">
      <CalendarSearchEnabled
        calendarSearchEnabled={realTimeBookings?.calendarSearchEnabled}
        updateCalendarSearchEnabled={updateCalendarSearchEnabled}
      />
      <DaysToCheckSlotsFor
        daysToCheckSlotsFor={realTimeBookings?.daysToCheckSlotsFor}
        updateDaysToCheckSlotFor={updateDaysToCheckSlotFor}
      />
      <div className="flex justify-end">
        <Button onClick={handleSave}>Save</Button>
      </div>
    </div>
  );
}
