import { useState } from "react";
import { Button } from "V2.0/common/button";
import { PlusIcon, XMarkIcon } from "@heroicons/react/16/solid";
import { Dialog, DialogBody, DialogTitle } from "V2.0/common/dialog";
import { StopCallRecordingForm } from "./StopCallRecordingForm";

function StopCallRecordingTriggerCreate({ onTriggerCreate }) {
  const [open, setOpen] = useState(false);

  const handleSubmit = (data) => {
    onTriggerCreate(data).then(() => setOpen(false));
  };

  return (
    <>
      <Button outline onClick={() => setOpen(true)}>
        <PlusIcon />
        Add Stop Recording Trigger
      </Button>
      <Dialog
        className=""
        size="2xl"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          <div className="text-lg flex flex-row justify-between">
            <div>Add Stop Recording Trigger</div>
            <div className="text-lg">
              <Button plain onClick={() => setOpen(false)}>
                <XMarkIcon />
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogBody className="text-sm/6">
          <StopCallRecordingForm
            stopCallRecordingTrigger={{}}
            onCancel={() => setOpen(false)}
            onSubmit={handleSubmit}
          />
        </DialogBody>
      </Dialog>
    </>
  );
}

export { StopCallRecordingTriggerCreate };
