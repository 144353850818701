import { useState } from "react";
import Joi from "joi-browser";
import Form from "V2.0/components/Form";

function PhoneNumberSelect(props) {
  const { workspaceId, onSubmit, onCancel, phoneNumbers } = props;
  const inputField = {
    identifier: "",
  };
  const [loading, setLoading] = useState(false);
  const template = [
    {
      name: "identifier",
      label: "Phone Number",
      type: "select",
      options: ["(None selected)", ...phoneNumbers].map((phoneNumber, idx) => {
        return {
          id: idx,
          value: phoneNumber,
          label: phoneNumber,
        };
      }),
    },
  ];
  const schema = {
    identifier: Joi.string().required(),
  };

  return (
    <Form
      inputField={inputField}
      template={template}
      schema={schema}
      loading={loading}
      setLoading={setLoading}
      buttonInfo={{
        buttons: "2",
        primaryLabel: "Add",
        secondaryLabel: "Cancel",
      }}
      onSubmit={onSubmit}
      handleSecondary={onCancel}
    />
  );
}

export { PhoneNumberSelect}