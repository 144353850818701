import { WelcomeMessageSettingsForm } from "./components/WelcomeMessageSettingsForm";

function InboundWelcomeMessageSettings(props) {
  const {
    loading,
    setLoading,
    schema,
    welcomeMessageSettings,
    onWelcomeMessageSubmit,
  } = props;

  const template = [
    {
      name: "shouldAgentSpeakFirstInbound",
      label: "Initiate Conversation",
      description: "Enable to let the Agent initiate the conversation",
      type: "toggle",
    },
    {
      name: "inboundWelcomeMessages",
      label: "Inbound Welcome Message",
      type: "textArea",
      style: { height: "40px" },
    },
  ];

  return (
    <WelcomeMessageSettingsForm
      onSubmit={onWelcomeMessageSubmit}
      welcomeMessageSettings={welcomeMessageSettings}
      schema={schema}
      template={template}
      loading={loading}
      setLoading={setLoading}
    />
  );
}

export { InboundWelcomeMessageSettings };
