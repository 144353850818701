import { useState, useEffect } from "react";
import TabsWithBadge from "V2.0/common/tabsWithBadge";
import Joi from "joi-browser";
import { Field, Label } from "V2.0/common/fieldset";
import { InboundWelcomeMessageSettings } from "./InboundWelcomeMessageSettings";
import { OutboundWelcomeMessageSettings } from "./OutboundWelcomeMessageSettings";
import { useVoiceSettings } from "../useVoiceSettings";

const TABS = {
  INBOUND: "Inbound Settings",
  OUTBOUND: "Outbound Settings",
};

const Tabs = {
  [TABS.INBOUND]: (props) => <InboundWelcomeMessageSettings {...props} />,
  [TABS.OUTBOUND]: (props) => <OutboundWelcomeMessageSettings {...props} />,
};

export function WelcomeMessageSettings(props) {
  const {
    welcomeMessageSettings: welcomeMessage,
    syncAssistant,
    workspaceId,
    assistantId,
  } = props;
  const { updateVoiceSettings } = useVoiceSettings({
    syncAssistant,
    workspaceId,
    assistantId,
  });

  const [welcomeMessageSettings, setWelcomeMessageSettings] = useState({
    inboundWelcomeMessages: "",
    outboundWelcomeMessages: "",
    shouldAgentSpeakFirstInbound: false,
    shouldAgentSpeakFirstOutbound: false,
  });
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS.INBOUND);

  const tabs = Object.values(TABS).reduce((acc, tab) => {
    return [...acc, { name: tab, href: "#", current: tab === activeTab }];
  }, []);

  useEffect(() => {
    if (welcomeMessage) {
      const { _id, inboundWelcomeMessages, outboundWelcomeMessages, ...rest } =
        welcomeMessage;

      setWelcomeMessageSettings((prevSettings) => ({
        ...prevSettings,
        ...rest,
        inboundWelcomeMessages: inboundWelcomeMessages
          ? inboundWelcomeMessages[0]
          : "",
        outboundWelcomeMessages: outboundWelcomeMessages
          ? outboundWelcomeMessages[0]
          : "",
      }));
    }
  }, [welcomeMessage]);

  const updateWelcomeMessageSettings = async (data) => {
    const payload = {
      welcomeMessageSettings: data,
    };
    updateVoiceSettings(payload);
    setLoading(false);
  };

  const schema = {
    inboundWelcomeMessages: Joi.string()
      .max(350)
      .allow(null, "")
      .label("Welcome message"),
    outboundWelcomeMessages: Joi.string()
      .allow(null, "")
      .max(350)
      .label("Welcome message"),
    shouldAgentSpeakFirstInbound: Joi.boolean()
      .required()
      .label("Initiate Conversation"),
    shouldAgentSpeakFirstOutbound: Joi.boolean()
      .required()
      .label("Initiate Conversation"),
  };

  return (
    <div className="mt-4 w-2/4 flex flex-col mx-auto">
      <Field>
        <Label>Welcome Message</Label>
      </Field>
      <div className="mt-2 flex flex-col border rounded-lg">
        <div className="">
          <TabsWithBadge
            className="px-4 border-b"
            tabs={tabs}
            onTabChange={(e, { tab }) => setActiveTab(tab.name)}
          />
          <div className="px-8 pb-8">
            {Tabs[activeTab]({
              loading,
              setLoading,
              welcomeMessageSettings,
              setWelcomeMessageSettings,
              schema,
              onWelcomeMessageSubmit: updateWelcomeMessageSettings,
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
