import Joi from "joi-browser";
import { useEffect, useState } from "react";
import Form from "V2.0/components/Form";
import { useVoiceSettings } from "./useVoiceSettings";

const interruptionSettingsFormModel = {
  vadInterruptionEnabled: {
    template: {
      label: "Detect Speech Activity to Interrupt Agent",
      description:
        "Enable this to pause voice agent response, as soon as the caller speaks. If not enabled, the agent will be interrupted only once the caller speaks more than 2 words",
      type: "toggle",
    },
    schema: Joi.boolean(),
  },
  speechThreshold: {
    template: {
      label: "Voice Sensitivity",
      type: "slider",
      min: 0.4,
      max: 0.9,
      step: 0.1,
    },
    schema: Joi.number(),
  },
};

const fields = Object.keys(interruptionSettingsFormModel).map((field) => {
  const template = interruptionSettingsFormModel[field].template;
  return {
    ...template,
    name: field,
  };
});

const schema = Object.keys(interruptionSettingsFormModel).reduce(
  (acc, field) => ({
    ...acc,
    [field]: interruptionSettingsFormModel[field].schema,
  }),
  {}
);

export function InterruptionSettings(props) {
  const { interruption, syncAssistant, workspaceId, assistantId } = props;
  const { updateVoiceSettings } = useVoiceSettings({
    syncAssistant,
    workspaceId,
    assistantId,
  });

  const [interruptionSettings, setInterruptionSettings] = useState({
    vadInterruptionEnabled: false,
    speechThreshold: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { _id, ...restInterruptionSettings } = interruption;
    setInterruptionSettings(restInterruptionSettings);
  }, [interruption]);

  const handleSave = async (data) => {
    const payload = {
      interruption: data,
    };
    updateVoiceSettings(payload);
    setLoading(false);
  };

  return (
    <div className="w-2/4 p-4 pb-8 border rounded-lg flex mx-auto mt-4">
      <Form
        inputField={interruptionSettings}
        template={fields}
        schema={schema}
        loading={loading}
        setLoading={setLoading}
        onSubmit={handleSave}
        buttonInfo={{ label: "Save", style: "right-side" }}
      />
    </div>
  );
}
