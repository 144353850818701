import React, { useState } from "react";

import { TableComponent } from "../components/TableComponent";
import { Button } from "V2.0/common/button";
import { Dialog, DialogTitle, DialogActions } from "V2.0/common/dialog";
import { DialogTitleWithClose } from "../components/Dialog/DialogTitleWithClose";

export function ExistingAPIs({ customApis, setActiveItem, onDelete }) {
  const [isOpen, setIsOpen] = useState(false);
  const [item, setItem] = useState(null);

  return (
    <>
      <TableComponent
        data={customApis}
        headers={[
          { key: "apiName", label: "Name" },
          { key: "apiURL", label: "URL" },
          { key: "apiMethod", label: "Method" },
        ]}
        onEdit={setActiveItem}
        onDelete={(item, index) => {
          setIsOpen(true);
          setItem(item);
        }}
      />

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Delete Custom API</DialogTitle>
        <DialogActions>
          <Button outline onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onDelete(item._id);
              setIsOpen(false);
            }}
          >
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
