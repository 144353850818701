import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "V2.0/common/table";
import { Button } from "V2.0/common/button";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/16/solid";
import { putExtraction } from "../../service";
import { useMandatoryFields } from "./useMandatoryFields";
import { Switch } from "V2.0/common/switch";

function ExtractionFieldsConfirm({
  extraction,
  newExtractions,
  workspaceId,
  assistantId,
  setNewExtractions,
  syncAssistant,
  setOpen,
  mandatoryFields,
  addToMandatoryField,
  removeFromMandatoryField,
  isMandatoryField,
  onSubmit,
}) {
  const [loading, setLoading] = useState(false);
  const MAX_LENGTH = 50;

  // Function to truncate the text
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  const handleDeleteConfirmation = (index) => {
    setNewExtractions((prevExtractions) =>
      prevExtractions.filter((_, i) => i !== index)
    );
  };

  const onConfirmFields = async () => {
    const updatedExtractionParameters = [
      ...extraction.extractionParameters,
      ...newExtractions,
    ];
    const payload = {
      extractionParameters: updatedExtractionParameters,
      mandatoryFields
    };

    try {
      setLoading(true);
      const response = await putExtraction({
        workspaceId,
        assistantId,
        payload,
      });
      if (response.status === 200) {
        syncAssistant((prevAssistant) => {
          return {
            ...prevAssistant,
            extraction: response.data,
          };
        });
        setNewExtractions([]);
        onSubmit();
        setLoading(false);
        console.log("success");
      }
    } catch (e) {
      setLoading(false);
      console.error("Error updating extractions");
    }
  };

  return (
    <div>
      <div>
        <Table className="">
          <TableHead>
            <TableRow>
              <TableHeader>Field name</TableHeader>
              <TableHeader>Type</TableHeader>
              <TableHeader>Instruction</TableHeader>
              <TableHeader>Mandatory Field</TableHeader>
              <TableHeader>Action</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {newExtractions.map((item, index) => (
              <TableRow key={index}>
                <TableCell className="font-medium">
                  {item.parameterName}
                </TableCell>
                <TableCell>{item.parameterType}</TableCell>
                <TableCell>
                  {truncateText(item.parameterDescription, MAX_LENGTH)}
                </TableCell>
                <TableCell>
                  <Switch
                    checked={isMandatoryField(item.parameterName)}
                    onChange={(checked) => {
                      checked
                        ? addToMandatoryField(item.parameterName)
                        : removeFromMandatoryField(item.parameterName);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <div className="-mx-2 -my-1.5 sm:-mx-2.5">
                    <Button
                      outline
                      onClick={() => handleDeleteConfirmation(index)}
                    >
                      <TrashIcon />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {newExtractions.length > 0 && (
          <div className="px-4 py-4 sm:px-6 flex justify-end gap-x-4">
            <Button plain onClick={() => setOpen(true)} disabled={loading}>
              <PlusCircleIcon />
              Add another field
            </Button>
            <Button loading={loading} onClick={onConfirmFields}>
              Confirm fields
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export { ExtractionFieldsConfirm };
