import { ChevronRightIcon } from "@heroicons/react/24/outline";

function SectionItem(props) {
    const { sectionName, onSectionClick, isActive, icon } = props;
    return (
      <div
        onClick={onSectionClick}
        className={`w-full h-12 flex items-center justify-between px-4 hover:bg-gray-300 hover:cursor-pointer ${
          isActive ? "bg-gray-200" : ""
        }`}
      >
        <div className="flex items-center gap-x-2">
          {icon && icon}
          <p className="font-medium text-sm">{sectionName}</p>
        </div>
      </div>
    );
  }

  export { SectionItem }